import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { StreamStateModel } from './model/stream.state.model';
import { StreamService, CreateStreamPayload, StreamInterface, JoinStreamPayload } from './stream.service';
import { StreamerStateModel } from './model/streamer.state.model';

export class SetDevices {
  static readonly type = '[Stream] Set Devices';
  constructor(public videoDeviceId, public audioDeviceId) {}
}

export class SetLocalStream {
  static readonly type = '[Stream] Set Local Stream';
  constructor(public stream) {}
}

export class SetRemoteStream {
  static readonly type = '[Stream] Set Remote Stream';
  constructor(public stream) {
    // console.log(("SET REMOTE STREAM ACTION CREATED");
  }
}

export class PublisherJoined {
  static readonly type = '[Stream] Publisher joined';
  constructor() {}
}

export class PublisherLeaved {
  static readonly type = '[Stream] Publisher Leaved';
  constructor() {
    // console.log(("###PublisherLeaved");
  }
}

export class SetParticipants {
  static readonly type = '[Stream] Set Participants';
  constructor(public participants: number[]) {}
}



export class GetToken {
  static readonly type = '[Stream] Get Token';
  constructor() {}
}

export class SetPublicStreamers {
  static readonly type = '[Stream] Set Public Streamers';
  constructor(public streamers: StreamerStateModel[] ) {
  }
}

export class SetPrivateStreamers {
  static readonly type = '[Stream] Set Private Streamers';
  constructor(public streamers: StreamerStateModel[] ) {
  }
}

export class HangupStream {
  static readonly type = '[Stream] Hangup Stream';
  constructor() {
  }
}


@State<StreamStateModel>({
  name: 'stream',
})
@Injectable()
export class StreamState {

  @Selector()
  static privateStreamers(state: StreamStateModel): StreamerStateModel[] {
    return state.privateStreamers;
  }

  @Selector()
  static publicStreamers(state: StreamStateModel): StreamerStateModel[] {
    return state.publicStreamers;
  }


  constructor(private readonly streamService: StreamService) {}



  @Action(GetToken)
  getToken(
    { patchState }: StateContext<StreamStateModel>,
  ) {
    return this.streamService.getToken()
    .pipe(
      tap(response => {
        patchState({
          janusToken: response.token,
         })
      })
    )
  }


  @Action(SetPrivateStreamers)
  setPrivateStreamers(
    { patchState }: StateContext<StreamStateModel>,
    { streamers }: SetPrivateStreamers
  ) {
    patchState({
      privateStreamers: streamers,
    })
  }

  @Action(SetPublicStreamers)
  setPublicStreamers(
    { patchState }: StateContext<StreamStateModel>,
    { streamers }: SetPublicStreamers
  ) {
    patchState({
      publicStreamers: streamers,
    })
  }
}
