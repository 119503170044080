export class StreamerTypeMapper {
  static typeMap = {
    loyal: 'Sadık',
    normal: 'Normal'
  }
  public static map(type: string) {
    if (type in this.typeMap)
      return this.typeMap[type];
    return type;
  }
}
