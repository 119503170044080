import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { environment } from 'src/environments/environment';
const routes: Routes = [
  { path: 'auth', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'reset-password/:token',
    component: LoginComponent,
    data: {
      param: 'reset-password',
      title: 'CafeCanlı'
    }
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'landing',
        component: LandingComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [LoginComponent, LandingComponent],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ], providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.google_client_id,
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook_client_id),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  exports: [RouterModule],
})
export class AuthModule { }
