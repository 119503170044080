import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserStatusInterface } from '../store/interface/user-status.interface';
import { StreamerStateModel } from '../store/model/streamer.state.model';
import { ChatInfoDataInterface, ChatInviteDataInterface, ChatLeaveDataInterface } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // observables for messages
  socketConnected: Subject<any> = new Subject<any>();
  conversations: Observable<any> = new Observable<any>();
  newConversation: Observable<any> = new Observable<any>();
  messages: Observable<any> = new Observable<any>();
  newMessage: Observable<any> = new Observable<any>();
  sentMessage: Observable<any> = new Observable<any>();
  messageJoined: Observable<any> = new Observable<any>();
  writing: Observable<any> = new Observable<any>();
  readMessage: Observable<any> = new Observable<any>();
  // observables for chat
  chatParticipants: Observable<any> = new Observable<any>();
  chatMessage: Observable<any> = new Observable<any>();
  chatJoined: Observable<any> = new Observable<any>();
  chatInvite: Observable<ChatInviteDataInterface> = new Observable<ChatInviteDataInterface>();
  chatLeave: Observable<ChatLeaveDataInterface> = new Observable<ChatLeaveDataInterface>();
  chatInfo: Observable<ChatInfoDataInterface> = new Observable<ChatInfoDataInterface>();
  stories: Observable<any> = new Observable<any>();
  earnings: Observable<number> = new Observable<number>();
  spendings: Observable<number> = new Observable<number>();
  login: Observable<boolean> = new Observable<boolean>();
  newStory: Observable<any> = new Observable<any>();
  exception: Observable<object> = new Observable<object>();

  newNotification: Observable<any> = new Observable<any>();

  // user status
  userStatus: Observable<UserStatusInterface | UserStatusInterface[]> = new Observable<
    UserStatusInterface | UserStatusInterface[]
  >();
  // streams
  streams: Observable<{ streamer: StreamerStateModel; type: 'public' | 'private' }[]> = new Observable();
}
