import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileStreamerService {

  prodUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getAllStreamers(obj){
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.prodUrl}streamers?${params}`);
  }
  getProfile(streamerId: number) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}`);
  }

  getProfileFollowers(streamerId: number) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}/followers`);
  }

  getProfileFollowings(streamerId: number) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}/followings`);
  }

  getProfileSeen(streamerId: number) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}/seen?sort=createdAt:desc`);
  }

  getProfileStory(streamerId: number) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}/story`);
  }


  updateStreamer(obj){
    return this.http.patch(`${this.prodUrl}streamer`, obj);
  }

  getStreamer() {
    return this.http.get(`${this.prodUrl}streamer`);
  }

  getStreamerStories(streamerId) {
    return this.http.get(`${this.prodUrl}streamers/${streamerId}/story`);
  }


}
