export class ErrorMapper {
  static typeMap = {
    Unauthorized: 'Giriş bilgileriniz geçersiz.',
    'forms.error.validator.email.invalid': 'Geçersiz e-posta adresi.',
    'forms.error.validator.required': 'Tüm alanlar doldurulmalıdır.',
    'forms.error.validator.email.not_unique': 'Bu emaile ait bir hesap zaten var.',
    'forms.error.validator.username.minlength': 'Kullanıcı adı en az 4 karakterden oluşmalıdır.',
    'forms.error.validator.username.not_unique': 'Bu kullanıcı adı alınmış.',
    'forms.error.validator.username.not_alphanumeric': 'Kullanıcı adı harflerden ve sayılardan oluşmalıdır.',
    'forms.error.validator.password.minlength': 'Şifreniz en az 8 karakterden oluşmalıdır.',
    "User already exists in database!": 'Kullanıcı adı daha önce alınmış. Lütfen başka kullanıcı adı seçiniz',
  };
  public static map(type: string) {
    if (type in this.typeMap) {
      return this.typeMap[type];
    }
    return type;
  }
}
