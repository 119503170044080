import { Component, OnInit } from '@angular/core';
import { GetStreamer } from 'src/app/store/streamer.state';
import { GetUser } from 'src/app/store/user.state';
import { Select, Store } from '@ngxs/store';
import { UserStateModel } from 'src/app/store/model/user.state.model';
import { UserState } from 'src/app/store/user.state';
import { Observable, Subject } from 'rxjs';
import { AuthState } from 'src/app/store/auth.state';
import { UtilService } from 'src/app/store/util.service';
import { UserTypeMapper } from 'src/app/utils/user-type.mapper';
import { ErrorMapper } from 'src/app/utils/error.mapper';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.css'],
})
export class PrivateLayoutComponent implements OnInit {
  isLogged :Boolean | Object = false;
  isStreamer: boolean;
  ownerType
  httpResponse;
  httpModal: boolean = false;
  @Select(UserState.getUser) user$: Observable<UserStateModel>
  @Select(AuthState.ownerType) ownerType$: Observable<string>;
  @Select(AuthState.token) token$: Observable<string>;
  _unsubscribeAll = new Subject();
  constructor(private store: Store, private utilService: UtilService) {}

  ngOnInit(): void {
    this.ownerType$.pipe(takeUntil(this._unsubscribeAll)).subscribe((type) => {
      this.ownerType = type;
      if (this.ownerType === 'Streamer') {
        this.isStreamer = true;
      } else if (this.ownerType === 'User') {
        this.isStreamer = false;
      } else if (this.ownerType === 'Guest') {
        return;
      }

      this.token$.pipe(takeUntil(this._unsubscribeAll)).subscribe((token) => {
        if (this.ownerType && this.ownerType === 'Guest') return;
        const isAuthenticated = token;
        this.isLogged = isAuthenticated;
      });
    });
    this.utilService.dataServiceItem.subscribe((data) => {
      if (data['title']) {
        // console.log("dataServiceItem");
        // console.log(data);
        this.messagePopup(data['title'], data['errorMessage'], data['icon'], data['colorCode']);

        data['title'] = null;
        // this.httpResponse = data;
        //  this.httpModal = true;
      }
    });

    $(function () {
      $('.hmtop h5').on('click', function () {
        $('#hm').toggleClass('activech');
        $('.hm-content').toggle();
        if ($('#hm').hasClass('activech')) {
          $('.hm-content').addClass('showinside');
        } else {
          $('.hm-content').removeClass('showinside');
        }
      });
      $('.closemsg').on('click', function () {
        $('#hm').removeClass('activech');
        $('.hm-content').removeClass('showinside');
        $('.hm-content').hide();
      });
      $('.tab-menu li').on('click', function () {
        $('#hm').addClass('activech');
        $('.hm-content').show();
        if ($('#hm').hasClass('activech')) {
          $('.hm-content').addClass('showinside');
        } else {
          $('.hm-content').removeClass('showinside');
        }
      });

      $(document).mouseup(function (e) {
        var container = $('.hm');

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.removeClass('activech');
        }
      });
    });

    $(document).ready(function () {
      $('.notif a').on('click', function (e) {
        e.preventDefault();
        $('.notif-content').stop().fadeToggle('1');
        e.preventDefault();
      });
      $('.member-status a').on('click', function (e) {
        e.preventDefault();
        $('.status-content').stop().fadeToggle('1');
      });
      $('.notif-follow a').on('click', function (e) {
        e.preventDefault();
        $('.notif-follow-content').stop().fadeToggle('fast');
        e.preventDefault();
      });
    });
    $(document).mouseup(function (e) {
      var container = $('.show-content');

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.fadeOut('fast');
      }
    });

    $(document).on('change', '#switch', function () {
      if (this.checked) {
        $('link[href="assets/css/theme1.css"]').attr('href', 'css/theme2.css');
      } else {
        $('link[href="assets/css/theme2.css"]').attr('href', 'css/theme1.css');
      }
    });

    // *** TO BE CUSTOMISED ***

    // *** END OF CUSTOMISABLE SECTION ***
    // You do not need to customise anything below this line
  }
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  style_cookie_name = 'style';
  style_cookie_duration = 30;
  style_domain = 'cafecanli.com';

  switch_style(css_title) {
    // You may use this script on your site free of charge provided
    // you do not remove this notice or the URL below. Script from
    // https://www.thesitewizard.com/javascripts/change-style-sheets.shtml
    var i, link_tag;
    for (i = 0, link_tag = document.getElementsByTagName('link'); i < link_tag.length; i++) {
      if (link_tag[i].rel.indexOf('stylesheet') != -1 && link_tag[i].title) {
        link_tag[i].disabled = true;
        if (link_tag[i].title == css_title) {
          link_tag[i].disabled = false;
        }
      }
      this.set_cookie(this.style_cookie_name, css_title, this.style_cookie_duration, this.style_domain);
    }
  }

  set_style_from_cookie() {
    var css_title = this.get_cookie(this.style_cookie_name);
    if (css_title.length) {
      this.switch_style(css_title);
    }
  }
  set_cookie(cookie_name, cookie_value, lifespan_in_days, valid_domain) {
    // https://www.thesitewizard.com/javascripts/cookies.shtml
    var domain_string = valid_domain ? '; domain=' + valid_domain : '';
    document.cookie =
      cookie_name +
      '=' +
      encodeURIComponent(cookie_value) +
      '; max-age=' +
      60 * 60 * 24 * lifespan_in_days +
      '; path=/' +
      domain_string;
  }

  cookie_value;
  get_cookie(cookie_name) {
    // https://www.thesitewizard.com/javascripts/cookies.shtml
    var cookie_string = document.cookie;
    if (cookie_string.length != 0) {
      var cookie_array = cookie_string.split('; ');
      for (let i = 0; i < cookie_array.length; i++) {
        this.cookie_value = cookie_array[i].match(cookie_name + '=(.*)');
        if (this.cookie_value != null) {
          return decodeURIComponent(this.cookie_value[1]);
        }
      }
    }
    return '';
  }

  findErrors(messages) {
    // console.log("findErrors");
    // console.log(messages);
    /* console.log(messages);
    console.log(Object.values(messages));
    if(messages){
      return Object.values(messages);
    } */
    return ErrorMapper.map(messages);
  }

  removeErrors() {
    this.httpModal = false;
    this.httpResponse = null;
  }

  messagePopup(title, text, icon, colorCode) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonColor: colorCode,
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Tamam',
    }).then((result) => {
      if (result.value) {
        // this.modalService.dismissAll();
      }
    });
  }
}
