import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StreamerStateModel, StreamerLimitsInterface } from './model/streamer.state.model';
import { tap, switchMapTo, catchError } from 'rxjs/operators';
import { ProfileStreamerService } from './profile-streamer.service';
import { StreamerService } from './streamer.service';
import { Observable } from 'rxjs';
import { WalletService } from './wallet.service';
import { of } from 'rxjs';
import { Logout } from 'src/app/store/auth.state';
import { WalletStateModel } from './model/wallet.state.model';
import { ChangeWalletState } from 'src/app/store/wallet.state';
export class GetStreamer {
  static readonly type = '[Streamer] GetStreamer';
  constructor() {}
}

export class Earned {
  static readonly type = '[Streamer] Earned';
  constructor(public credit: number) {}
}
export class SetStreamerProfile {
  static readonly type = '[Streamer] Set Profile';
  constructor(public profile: any) {}
}

export class SetWalletStateInsideStreamer {
  static readonly type = '[Streamer] Set Wallet State Inside Streamer';
  constructor(public wallet: WalletStateModel) {}
}

@State<StreamerStateModel>({
  name: 'streamer',
})
@Injectable()
export class StreamerState {
  @Selector()
  static getUser(state: StreamerStateModel) {
    return state;
  }

  @Selector()
  static getTotalWeeklyStreamingSeconds(state: StreamerStateModel): number {
    return state.totalWeeklyStreamingSeconds;
  }

  @Selector()
  static getStreamerId(state: StreamerStateModel) {
    return state._id;
  }

  @Selector()
  static limits(state: StreamerStateModel): StreamerLimitsInterface {
    return state.limits;
  }

  @Selector()
  static getCreditToRevenue(state: StreamerStateModel): number {
    return state.limits.creditToRevenue;
  }

  constructor(
    private profileStreamerService: ProfileStreamerService,
    private streamerService: StreamerService,
    private walletService: WalletService,
  ) {}

  @Action(SetWalletStateInsideStreamer)
  setWalletState(ctx: StateContext<WalletStateModel>, wallet) {
    ctx.dispatch(new ChangeWalletState(wallet.wallet));
  }

  @Action(GetStreamer)
  getStreamer(ctx: StateContext<StreamerStateModel>) {
    return this.profileStreamerService.getStreamer().pipe(
      tap((response: StreamerStateModel) => {
        ctx.patchState({
          _id: response._id,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt,
          streamerId: response.streamerId,
          email: response.email,
          emailVerified: response.emailVerified,
          status: response.status,
          username: response.username,
          streamerName: response.streamerName,
          firstName: response.firstName,
          lastName: response.lastName,
          birthDate: response.birthDate,
          type: response.type,
          phone: response?.phone,
          otherLang: response.otherLang,
          otherLang2: response.otherLang2,
          country: response.country,
          lang: response.lang,
          birthYear: response.birthYear,
          privacy: response.privacy,
          gender: response.gender,
          profile: response.profile,
          penalty: response.penalty,
          pausePayment: response.pausePayment,
          paymentMethod: response.paymentMethod,
          perHourCredit: response.perHourCredit,
          paymentQuota: response.paymentQuota,
          identification: response.identification,
          social: response.social,
          creditByPerMinute: response.creditByPerMinute,
        });
      }),
      switchMapTo(
        this.streamerService.getSettingsLimits().pipe(
          tap((response: any) => {
            ctx.patchState({
              limits: {
                creditToRevenue: response.creditToRevenue,
                maxPrivateCredit: response.maxPrivateCredit,
                minPaymentQuota: response.minPaymentQuota,
                minPrivateCredit: response.minPrivateCredit,
                privateCreditPerMinute: response.privateCreditPerMinute,
                publicCreditPerHour: response.publicCreditPerHour,
              },
            });
          }),
        ),
      ),
      switchMapTo(
        this.walletService.cumulativeBalance().pipe(
          tap((response: WalletStateModel) => {
            const walletObj = {
              audioCredit: response?.audioCredit || 0,
              audioPrice: response?.audioPrice || 0,
              commissionCredit: response?.commissionCredit || 0,
              commissionPrice: response?.commissionPrice || 0,
              giftCredit: response?.giftCredit || 0,
              giftPrice: response?.giftPrice || 0,
              privateStream: response?.privateStream || 0,
              privateStreamPrice: response?.privateStreamPrice || 0,
              publicStream: response?.publicStream || 0,
              publicStreamPrice: response?.publicStreamPrice || 0,
              totalCredit: response?.totalCredit || 0,
              totalPrice: response?.totalPrice || 0,
              totalWeeklyStreamingSeconds: response?.totalWeeklyStreamingSeconds || 0,
            }
            ctx.dispatch(new SetWalletStateInsideStreamer(walletObj));
            ctx.patchState(walletObj);
          }),
        ),
      ),
      catchError((err) => {
        ctx.dispatch(new Logout());
        return of('error', err);
      }),
    );
  }

  @Action(Earned)
  earned({ patchState, getState }: StateContext<StreamerStateModel>, { credit }: Earned) {
    const state = getState();
    patchState({
      totalCredit: state?.totalCredit + credit,
      totalPrice: Number.parseFloat((state?.totalCredit * state.limits.creditToRevenue).toFixed(2)),
    });
  }

  @Action(SetStreamerProfile)
  setProfile({ patchState }: StateContext<StreamerStateModel>, { profile }: SetStreamerProfile) {
    return patchState({ profile });
  }
}
