import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  updateMetaInfo(content) {
    this.meta.updateTag({ name: 'description', content });
    // this.meta.updateTag({ name: 'author', content: author });
    // this.meta.updateTag({ name: 'keywords', content: category });
  }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            // @ts-ignore
            while (route.firstChild && route.firstChild.data.value.title) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data),
        )
        .subscribe((event) => {
          this.titleService.setTitle(event.title);
          this.updateMetaInfo(event.description);
        });
    } else {
      this.titleService.setTitle(title);
    }
  }
}
