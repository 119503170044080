import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AuthState, Login, Register, SocialLogin } from '../../store/auth.state';
import { Navigate } from '@ngxs/router-plugin';
import { SocialAuthService } from 'angularx-social-login';
import { AuthService } from '../../store/auth.service';
import Swal from 'sweetalert2';
import { GetStreamer } from 'src/app/store/streamer.state';
import { GetUser } from 'src/app/store/user.state';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMapper } from 'src/app/utils/error.mapper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { comparePasswords } from '@ngx-validate/core';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  loginErrors: string;
  forgotPasswordError: string;
  sentEmailSuccessfully = false;
  registerErrors: [];
  public loginPopup = false;
  loginForm: FormGroup;
  registerForm: FormGroup;
  usernameImg: string;
  emailImg: string;
  forgotForm: FormGroup;
  resetPasswordForm: FormGroup;
  filterForm: FormGroup;
  apiUrl = environment.prodUrl;
  public showRegister = false;
  public showLogin = false;
  public showForgotPassword = false;
  public showPasswordReset = false;
  resetPasswordFormError = '';
  token;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    public _location: Location,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('#register')) {
      this.openForm('showRegister');
    } else {
      this.openForm('showLogin');
    }
    this.route.data.pipe(takeUntil(this.componentDestroyed$)).subscribe((routeData) => {
      if (routeData.param === 'reset-password') {
        this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe((params) => {
          this.token = params['token'];
        });
        this.openForm('showPasswordReset');
      }
    });

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.forgotForm = this.fb.group({
      email: ['', Validators.required],
    });
    this.registerForm = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.resetPasswordForm = this.fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(8)]],
        password_confirmation: [null, [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: [comparePasswords(['password', 'password_confirmation'])],
      },
    );
    const data = this.store.selectSnapshot(AuthState.token);
    if (!data) {
      this.loginPopup = true;
    } else {
      const ownerType = this.store.selectSnapshot(AuthState.ownerType);
      if (ownerType === 'Streamer') {
        this.store.dispatch(new Navigate(['/streamer']));
      } else if (ownerType === 'User') {
        this.store.dispatch(new Navigate(['/member/index']));
      }
    }

    this.socialAuthService.authState.subscribe((user) => {
      // console.log('social', user);

      this.store.dispatch(new SocialLogin(user.authToken, user.provider)).subscribe(
        (success) => {
          const ownerType = this.store.selectSnapshot(AuthState.ownerType);
          if (ownerType === 'Streamer') {
            this.store.dispatch(new Navigate(['/streamer']));
          } else {
            this.store.dispatch(new Navigate(['/member/index']));
          }
        },
        (error) => {
          // console.log(error);
        },
      );
    });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
  openForm(formToshow) {
    const formList = ['showLogin', 'showRegister', 'showForgotPassword', 'showPasswordReset'];
    formList.forEach((form) => {
      if (form === formToshow) {
        this[form] = true;
      } else {
        this[form] = false;
      }
    });
  }

  submit() {
    this.store
      .dispatch(
        new Login(
          this.loginForm.value['email'],
          this.loginForm.value['password'],
          sessionStorage.getItem('referer') || '',
          environment.appType,
        ),
      )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (success) => {
          const ownerType = this.store.selectSnapshot(AuthState.ownerType);
          localStorage.setItem('isGuest', 'false');
          if (ownerType === 'Streamer') {
            this.store.dispatch(new GetStreamer());
            this.store.dispatch(new Navigate(['/streamer']));
          } else {
            this.store.dispatch(new GetUser());
            this.store.dispatch(new Navigate(['/member/index']));
          }
        },
        (error) => {
          this.loginErrors = ErrorMapper.map(error.error.message);
        },
      );
  }

  forgot() {
    if (!this.forgotForm.controls.email.valid) {
      this.forgotPasswordError = 'Geçerli bir e-posta adresi giriniz.';
      return;
    }
    // console.log(this.forgotForm.value);
    this.authService
      .resetPassword(this.forgotForm.value)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.sentEmailSuccessfully = true;
        },
        (error) => {
          let data = {
            title: 'Başarısız',
            icon: 'error',
            text: error.error.message,
          };
          this.alertPopup(data);
        },
      );
  }
  changePasswordSubmit() {
    this.authService
      .setPassword(this.token, { password: this.resetPasswordForm.value['password'] })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data: any) => {
          this.store
            .dispatch(
              new Login(
                data['email'],
                this.resetPasswordForm.value['password'],
                sessionStorage.getItem('referer') || '',
                environment.appType,
              ),
            )
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              (success) => {
                const ownerType = this.store.selectSnapshot(AuthState.ownerType);
                localStorage.setItem('isGuest', 'false');
                if (ownerType === 'Streamer') {
                  this.store.dispatch(new GetStreamer());
                  this.store.dispatch(new Navigate(['/streamer']));
                } else {
                  this.store.dispatch(new GetUser());
                  this.store.dispatch(new Navigate(['/member/index']));
                }
              },
              (error) => {
                this.loginErrors = ErrorMapper.map(error.error.message);
              },
            );
        },
        (error) => {
          this.loginErrors = error.error.message;
        },
      );
  }
  checkIfUserNameExist() {
    if (this.registerForm.controls.username.valid) {
      this.authService.checkInputIsUnique('USERNAME', this.registerForm.value['username']).subscribe(
        (response) => {
          this.usernameImg = 'assets/img/valid.svg';
        },
        (error) => {
          this.usernameImg = 'assets/img/error.svg';
          const data = {
            title: 'Kullanıcı Adı Hatası',
            icon: 'error',
            text: 'Kullanıcı adı daha önce alınmış. Lütfen başka kullanıcı adı seçiniz',
          };
          this.alertPopup(data);
        },
      );
    } else {
      this.usernameImg = 'assets/img/error.svg';
    }
  }
  checkIfMailExist() {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
    if (this.registerForm.controls.email.valid && emailRegex.test(this.registerForm.value['email'])) {
      this.authService.checkInputIsUnique('EMAIL', this.registerForm.value['email']).subscribe(
        (response) => {
          this.emailImg = 'assets/img/valid.svg';
        },
        (error) => {
          this.emailImg = 'assets/img/error.svg';
          const data = {
            title: 'Email Adı Hatası',
            icon: 'error',
            text: 'Email daha önce alınmış. Lütfen başka email seçiniz',
          };
          this.alertPopup(data);
        },
      );
    } else {
      this.emailImg = 'assets/img/error.svg';
    }
  }
  register() {
    this.store
      .dispatch(
        new Register(
          this.registerForm.value['username'],
          this.registerForm.value['email'],
          this.registerForm.value['password'],
          sessionStorage.getItem('referer') || '',
          environment.appType,
        ),
      )
      .subscribe(
        (success) => {
          localStorage.setItem('isGuest', 'false');
          this.store.dispatch(new Navigate(['/member/index']));
        },
        (error) => {
          const errorMessage = Array.isArray(error.error.message) ? error.error.message[0] : error.error.message;
          
          let data = {
            title: 'Kayıt oluşturulamadı',
            text: errorMessage ?? 'Lütfen hatalı alanları düzeltin.',
            icon: 'error',
          };
          this.alertPopup(data);
        },
      );
  }

  clickedOut(event): void {
    if (event.target.className === 'modal-login-overlay modal-login-toggle') {
      this.loginPopup = false;
    }
  }

  changeLogin(): void {
    this.loginPopup = !this.loginPopup;
  }

  signInWithGoogle(): void {
    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  ngAfterViewInit(): void {
    $(function (): void {
      $(window).on('scroll', function () {
        $('body').removeClass('scrolling');
        $('body').removeClass('scrollingmsg');
      });
      $('.register').on('click', function (e) {
        $('.loginf').addClass('slidedown');
        $('.loginf').removeClass('slideup');
        $('.loginf').removeClass('slideuplogin');
        $('.loginr').removeClass('slidedown');
        $('.loginr').addClass('slideup');
        $('.loginp').removeClass('slideupp');
        $('.loginp').addClass('slidedown');
        e.preventDefault();
      });
      $('.loginb').on('click', function (e) {
        $('.loginf').removeClass('slidedown');
        $('.loginf').addClass('slideuplogin');
        $('.loginr').addClass('slidedown');
        $('.loginr').removeClass('slideup');
        $('.loginp').removeClass('slideupp');
        $('.loginp').addClass('slidedown');
        e.preventDefault();
      });
      $('.fpass').on('click', (e) => {
        $('.loginf').removeClass('slideup');
        $('.loginf').removeClass('slideuplogin');
        $('.loginf').addClass('slidedown');
        $('.loginr').removeClass('slideup');
        $('.loginr').addClass('slidedown');
        $('.loginp').removeClass('slidedown');
        $('.loginp').addClass('slideupp');
        e.preventDefault();
      });
    });
  }

  isValidEmail(validStatus, value): string {
    console.log(validStatus);
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
    if (validStatus && emailRegex.test(value)) {
      this.emailImg = 'assets/img/valid.svg';
    } else {
      this.emailImg = 'assets/img/error.svg';
    }
    return this.emailImg;
  }
  isValidUsernameCharacters(event): boolean {
    return event.key.match(/[^a-zA-Z0-9]/g) === null;
  }
  isValidPassword(password, form, fieldName?): string {
    const passwordRegex = /^(?=.{8,}$)(?=[^A-Za-z]*[A-Za-z])(?=[^0-9]*[0-9])(?!.*(.)\1{4})/g;
    if (password.valid && passwordRegex.test(form.value[fieldName || 'password'])) {
      return 'assets/img/valid.svg';
    }
    return 'assets/img/error.svg';
  }
  comparePasswords(): void {
    if (this.resetPasswordForm.value['password'] !== this.resetPasswordForm.value['password_confirmation']) {
      this.resetPasswordFormError = 'Şifreler eşleşmiyor';
    }
  }
  alertPopup(data): void {
    Swal.fire({
      title: data.title,
      text: data.text,
      icon: data.icon,
      confirmButtonText: 'Tamam',
    });
  }
}
