import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { RouterModule, Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthState } from './store/auth.state';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { UserState } from './store/user.state';
import { ApiInterceptor } from './store/api.interceptor';
import { StreamerState } from './store/streamer.state';
import { NotificationState } from './store/notification.state';
import { SocialLoginModule } from 'angularx-social-login';
import { ConversationState } from './store/conversation.state';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { StreamState } from './store/stream.state';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { WalletState } from './store/wallet.state';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}


const routes: Routes = [
  {
    path: '',
    redirectTo: 'member/index',
    pathMatch: 'full',
    data: {
      title: 'CafeCanlı : Canlı Sohbet',
      description:
        'Görüntülü canlı sohbet. Günün her saati ister mesajlaşın, ister sesli, ister görüntülü görüşme yapın.',
    },
  },
  {
    path: '',
    // resolve: [UserResolver],
    data: {
      title: 'CafeCanlı Mobil : Canlı Sohbet',
      description: 'Görüntülü canlı sohbet. Günün her saati ister mesajlaşın, ister sesli, ister görüntülü görüşme yapın.',
    },
    children: [
      {
        path: 'member',
        loadChildren: () =>
          import('./member/member.module').then(
            (m) => m.MemberModule
          ),
        data: {
          title: 'CafeCanlı Mobil : Canlı Sohbet',
          description: 'Görüntülü canlı sohbet. Günün her saati ister mesajlaşın, ister sesli, ister görüntülü görüşme yapın.',
        },
      },
      {
        path: 'streamer',
        loadChildren: () =>
          import('./streamer/streamer.module').then(
            (m) => m.StreamerModule
          ),
        data: {
          title: 'CafeCanlı Mobil : Canlı Sohbet',
          description: 'Görüntülü canlı sohbet. Günün her saati ister mesajlaşın, ister sesli, ister görüntülü görüşme yapın.',
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then(
            (m) => m.ProfileModule
          ),
        data: {
          title: 'CafeCanlı Mobil : Profil',
          description: 'CafeCanli Mobil Profil Sayfası',
        },
      },
      {
        path: 'stream',
        loadChildren: () =>
          import('./modules/member-chat/member-chat.module').then(
            (m) => m.MemberChatModule
          ),
        data: {
          title: 'CafeCanlı Mobil',
        },
      },
      {
        path: 'live',
        loadChildren: () =>
          import('./modules/streamer-chat/streamer-chat.module').then(
            (m) => m.StreamerChatModule
          ),
        data: {
          title: 'CafeCanlı Mobil',
        },
      },
      {
        path: 'message',
        loadChildren: () =>
          import('./modules/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
        data: {
          title: 'CafeCanlı Mobil',
        },
      }
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/login',
  },
];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    AppRoutingModule,
    NgxsModule.forRoot([AuthState, UserState, StreamerState, NotificationState, ConversationState, StreamState, WalletState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.accessToken', 'auth.email', 'auth.name', 'auth.ownerType', 'conversation'],
    }),
    NgxsRouterPluginModule.forRoot(),
    HttpClientModule,
    SocialLoginModule,
    NgScrollbarModule,
    AuthModule,
    ImageCropperModule,
    HammerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
