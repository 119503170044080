<div class="footer-info" *ngIf="ownerType !== 'Streamer'" [ngStyle]="isMessageUrl() && {display: 'none'}">
  <div class="container-fluid">
    <!-- <div class="col-xs-8 pl0">
        <a class="campaignlink"    *ngIf="activeCampaign">
          <p>{{ activeCampaign.credit.credit }} <span class="finfospan">Kredi</span> alımına {{ activeCampaign.bonus }} <span class="finfospan">Kredi</span> hediye!</p>
          <p class="finfosecond" *ngIf="finalValue">Kalan süre: <span class="finfospan" > {{ finalValue}}</span></p>
        </a>
      </div> -->
    <div style="display:flex; align-items: center;justify-content: center;padding-left: 0;" class="col-xs-12 pr0">
      <a class="livesupport" (click)="toggleChatWidget()">CANLI DESTEK</a>
    </div>

  </div>
</div>


<div class="footer-info" *ngIf="ownerType === 'Streamer'" [ngStyle]="isMessageUrl() && {display: 'none'}">

  <div class="container-fluid">
    <div class="col-xs-12 pr0 center">
      <a class="livesupportstream" (click)="toggleChatWidget()">CANLI DESTEK</a>
    </div>
  </div>
</div>