<body style="height: 100%; padding-bottom: 0" class="loginbody">
  <div id="video-bg">
    <video id="videoBG" playsInline autoplay [muted]="true" loop>
      <source src="./assets/video/login.mp4" type="video/mp4" />
    </video>
  </div>

  <section class="login-section">
    <button class="go-back-button btn" (click)="_location.back()">X</button>
    <header class="login-header">
      <div><img alt="" src="./assets/img/loginlogo.svg" /></div>
      <div>Gerçek insanlar. Canlı yayında.</div>
    </header>
    <div *ngIf="!showForgotPassword" class="socials">
      <a class="social-image facebook" href="{{ apiUrl + 'auth/facebook/login' }}"><img alt="" width="32" height="32" src="./assets/img/fb.svg" /> Facebook</a>
      <a class="social-image google" href="{{ apiUrl + 'auth/google/login' }}"><img alt="" width="32" height="32" src="./assets/img/gg.svg" /> Google</a>
    </div>
    <p *ngIf="showLogin || showRegister" class="another-option">E-Posta İle {{showLogin ? 'Giriş': ' Kayıt'}}</p>
    <form
    *ngIf="showLogin"
      [ngClass]="{ hidden: !showLogin, fade: showLogin }"
      class="form"
      [formGroup]="loginForm"
    >
      <div class="credentials">
        <input
          (focus)="loginErrors = ''"
          formControlName="email"
          type="email"
          class="mail"
          placeholder="Kullanıcı adı ya da e-posta"
          value=""
        />
        <input
          (focus)="loginErrors = ''"
          type="password"
          formControlName="password"
          class="password"
          placeholder="Şifre"
        />
      </div>

      <p class="formerror" *ngIf="loginErrors">{{ loginErrors }}</p>
      <button class="login-button" type="submit" (click)="submit()" [disabled]="!loginForm?.valid">Giriş Yap</button>
    </form>
    <form  *ngIf="showRegister" style="flex-basis: 38%;" [ngClass]="{ hidden: !showRegister, fade: showRegister }" class="form" [formGroup]="registerForm">
      <div class="credentials">
        <div class="register-input-wrapper">
          <input
            (focus)="loginErrors = ''"
            (focusout)="checkIfUserNameExist()"
            type="text"
            maxlength="15"
            formControlName="username"
            class="username"
            placeholder="Kullanıcı adı"
            value=""
            (keypress)="isValidUsernameCharacters($event)"
          />
          <img alt="" class="verfyinput" *ngIf="registerForm?.controls?.username?.touched" [src]="usernameImg" />
        </div>
        <div class="register-input-wrapper">
          <input
            (focus)="loginErrors = ''"
            (focusout)="checkIfMailExist()"
            type="email"
            class="email"
            email="true"
            formControlName="email"
            placeholder="E-posta"
            value=""
          />
          <img alt="" class="verfyinput" *ngIf="registerForm?.controls?.email?.touched" [src]="emailImg" />
        </div>
        <div class="register-input-wrapper">
          <input
            (focus)="loginErrors = ''"
            type="password"
            formControlName="password"
            class="password"
            placeholder="Şifre"
          />
          <img alt="" class="verfyinput" *ngIf="registerForm?.controls?.password?.touched" [src]="isValidPassword(registerForm?.controls?.password, registerForm)" />
        </div>
      </div>
      <div class="password-rules">
        <span>&#8226; 8 karakter</span>
        <span>&#8226; 1 büyük harf</span>
        <span>&#8226; 1 küçük harf</span>
      </div>
      <label class="container"
        >Kullanıcı sözleşmesini okudum, onaylıyorum.
        <input (focus)="loginErrors = ''" type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>

      <button class="login-button" type="submit" (click)="register()" [disabled]="!registerForm?.valid">Kayıt Ol</button>
    </form>

    <form  *ngIf="showForgotPassword" [ngClass]="{ hidden: !showForgotPassword, fade: showForgotPassword }" class="form" [formGroup]="forgotForm">
      <div class="credentials">
        <input
          type="email"
          class="email"
          email="true"
          formControlName="email"
          placeholder="E-posta"
          value=""
          (focus)="forgotPasswordError = ''"
        /><img
          class="verfyinput"
          *ngIf="forgotForm?.controls?.email?.touched"
          [src]="isValidEmail(forgotForm?.controls?.email?.valid, forgotForm.value['email'])"
        />
      </div>
      <p class="formerror" *ngIf="forgotPasswordError">
        {{ forgotPasswordError }}
      </p>
      <p class="form-succes" *ngIf="sentEmailSuccessfully">
        Bu e-postaya bağlı bir üyelik var ise, şifre sıfırlama mailini kontrol edebilirsiniz.
      </p>
      <button class="login-button" type="submit" (click)="forgot()">ŞİFREMİ GÖNDER</button>
    </form>
    <form [ngClass]="{ hidden: !showPasswordReset, fade: showPasswordReset }" class="form" [formGroup]="resetPasswordForm">
      <div class="credentials">
        <input
            (focus)="resetPasswordFormError = ''"
            type="password"
            formControlName="password"
            class="password"
            placeholder="Şifre"
          />
          <img alt="" class="verfyinput" *ngIf="resetPasswordForm?.controls?.password?.touched" [src]="isValidPassword(resetPasswordForm?.controls?.password, resetPasswordForm)" />
          <input
          (focus)="resetPasswordFormError = ''"
          (focusout)="comparePasswords()"
          type="password"
          formControlName="password_confirmation"
          class="password"
          placeholder="Şifre tekrar"
        />
        <img alt="" style="top:67px" class="verfyinput" *ngIf="resetPasswordForm?.controls?.password_confirmation?.touched" [src]="isValidPassword(resetPasswordForm?.controls?.password_confirmation,resetPasswordForm ,'password_confirmation' )" />

        </div>
        <p class="formerror" *ngIf="resetPasswordFormError">
          {{ resetPasswordFormError }}
        </p>
      <p class="form-succes">"Şifremi Değiştir"e tıkladığınızda, şifreniz değişecek ve yeni şifrenizle sisteme otomatik
        olarak<br/> giriş yapacaksınız.</p>

      <button class="login-button" [disabled]="resetPasswordFormError" type="submit" (click)="changePasswordSubmit()">ŞİFREMİ DEĞİŞTİR</button>
    </form>
    <div class="login-page-links">
      <a (click)="openForm('showLogin')" *ngIf="showRegister || showForgotPassword || showPasswordReset"
        >Giriş Yap</a
      >
      <a (click)="openForm('showRegister')" *ngIf="!showRegister">Hızlı Kayıt Ol</a>
      <a (click)="openForm('showForgotPassword')" *ngIf="!showForgotPassword && !showPasswordReset">Şifremi Yenile</a>
    </div>
  </section>

  <app-footer></app-footer>
</body>
