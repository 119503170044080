import { NgModule } from '@angular/core';
import { ProfileUsernameWarningBar } from './profile-username-warning-bar/profile-username-warning-bar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [ProfileUsernameWarningBar],
  imports: [
    ReactiveFormsModule, NgxSmartModalModule.forRoot()],
   exports: [
    ProfileUsernameWarningBar
  ]
})
export class UsernameWarningBarModule { }
