import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthState, Logout } from 'src/app/store/auth.state';
import { MemberService } from 'src/app/store/member.service';
import { StreamerStateModel } from 'src/app/store/model/streamer.state.model';
import { NotificationState } from 'src/app/store/notification.state';
import { SharedService } from 'src/app/store/shared.service';
import { GetStreamer, StreamerState } from 'src/app/store/streamer.state';
import { ConversationState, IncUnreadCount, SetUnreadCount } from '../../store/conversation.state';
import { TokenOwnerEnum } from '../../utils/token-owner.enum';
import { OwnerUtil } from '../../utils/owner.util';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../../store/message.service';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-header-streamer',
  templateUrl: './header-streamer.component.html',
  styleUrls: ['./header-streamer.component.css']
})
export class HeaderStreamerComponent implements OnInit {

  headerInfo;
  messages = 0;
  notifyForm: FormGroup;
  streamerData;
  @Select(ConversationState.unreadCount) unreadCount$: Observable<number>;
  @Select(StreamerState.getUser) streamer$: Observable<StreamerStateModel>;
  @Select(NotificationState.getAnnouncementNotificationCount) announcement$: Observable<number>;
  @Select(NotificationState.getFollowNotificationsCount) follow$: Observable<number>;
  private ownerType: TokenOwnerEnum;
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private sharedService: SharedService,
    private store: Store,
    private memberService: MemberService,
    private messageService: MessageService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.ownerType = this.store.selectSnapshot(
      AuthState.ownerType,
    ) as TokenOwnerEnum;

    this.notifyForm = this.fb.group({
      limit: [5],
      justUnseen: [true],
    });
    this.streamer$.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      this.headerInfo = data;
      this.streamerData = data;
      if (!this.streamerData) {
        this.store.dispatch(new GetStreamer());
        this.streamerData = data;
      } else {
        this.getUnreadMessages();
      }
    });
    this.messageService.join();
    this.messageService.getConversations();
    this.subscribeNewMessage();
    this.getUnreadMessages();
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  getUnreadMessages() {
    this.sharedService.conversations.subscribe(conversations => {
      const count = (conversations as any).docs.reduce((acc, item) => {
        return acc + item[this.friendType() + 'UnreadCount'];
      }, 0);

      // this.messages = (conversations as any).docs.forEach(conversation => this.messages += conversation['userUnreadCount']);
      this.store.dispatch(new SetUnreadCount(count));
    });
  }
  private subscribeNewMessage() {
    this.sharedService.newMessage
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((message) => {
        if (message.fromType !== this.ownerType) {
          this.store.dispatch(new IncUnreadCount());
        }
      });
  }
  ngAfterViewInit() {
    this.loadjQuery();
  }
  public friendType() {
    return OwnerUtil.ownerToggle(this.ownerType).toLowerCase();
  }

  loadjQuery() {


    $(document).ready(function () {

      $('#nav_list').on('click', function () {
        $(this).toggleClass('activex');
        $('.pushmenu-push').toggleClass('pushmenu-push-toright');
        $('.pushmenu-left').toggleClass('pushmenu-open');
        $("body").toggleClass("oh");

      });
      $(".menu-overlay").on('click', function () {
        $(".pushmenu").removeClass('pushmenu-open');
        $(".menu-overlay").removeClass("showoverlay");
        $("#nav_list").removeClass('closebutton');
        $("#nav_list").removeClass('activex');
        $("body").removeClass("oh");
      });
      $(".navbar-toggle").on('click',
        function (e) {
          $(this).toggleClass("closebutton");
          $(".menu-overlay").toggleClass("showoverlay");
          e.preventDefault();
        }
      );
      $(function () {
        $(window).on("scroll", function () {
          if ($(window).scrollTop() > 50) {
            // $('.navbar').addClass('statichead');
            // $('body').addClass('scrolling');
            $("#bottom-nav-el").addClass("bottom-nav");

          } else {
            //remove the background property so it comes transparent again (defined in your css)
            // $('.navbar').removeClass('statichead');
            // $('body').removeClass('scrolling');
            $("#bottom-nav-el").removeClass("bottom-nav");

          }
        });
      });
      $(".note-wrapper").on('click',
        function (e) {
          $(this).toggleClass("show-note");
          $(this).find(".notradius").removeClass("unreadnot");
          $(this).find(".notradius").addClass("readnot");
          e.preventDefault();
        }
      );
    });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
