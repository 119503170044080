<div *ngIf="sideBarInfo?._id && !sideBarInfo?.guestId" class="menu-overlay">
</div>
<div *ngIf="sideBarInfo?._id && !sideBarInfo?.guestId" class="pushmenu pushmenu-left">
    <div class="menu-head-ctn">
        <div class="menu-head" [ngStyle]="{'background-image': profilePhoto(sideBarInfo.profile?.picture?.path)}"></div>
    </div>
    <div class="nav-info">
        <div class="nav-info-left">
            <p class="follower-count">{{followerCount}}</p>
            <p class="follower-title">TAKİPÇİ</p>
        </div>
        <div class="nav-info-right">
            <p class="follower-count">{{followingCount}}</p>
            <p class="follower-title">TAKİP</p>
        </div>
    </div>
    <div class="menupp">
        <div class="menuppctn">
          <div class=" sidebar-profile-pic" [ngStyle]="{'background':profilePhoto(sideBarInfo.profile?.picture?.path)}"></div>
        </div>
        <h3 class="menu-uname">{{ sideBarInfo.username }}</h3>
        <h4 class="menu-ustatus"> {{ getUserType(sideBarInfo.type?.type) }} Üye <ng-container *ngIf="sideBarInfo.type?.endAt">  <span>({{
            findRestOfTheDaysFromEnd(sideBarInfo.type?.endAt)
          }}</span> Gün)</ng-container> </h4>
    </div>
    <ul class="left-list">
        <li><a class="trendler" [routerLink]="['/member/trends']" routerLinkActive="active">Trendler</a></li>
        <li><a class="kredial" routerLink="/member/buycredit" routerLinkActive="active">Kredi Satın Al</a></li>
        <li><a class="mesajlar" [routerLink]="['/message/box']" routerLinkActive="active">Mesajlar</a>
          <span *ngIf="messages > 0">{{ messages }}</span></li>
        <li><a class="bildirimler" [routerLink]="['/member/notifications']" routerLinkActive="active">Bildirimler</a>
          <span *ngIf="(announcementNotification$ | async)||(followNotification$ | async)">
            {{(announcementNotification$ | async)+(followNotification$ | async)}}</span></li>
        <li><a class="takipliste" [routerLink]="['/member/followers']" routerLinkActive="active">Takip Listeleri</a>
          <span *ngIf="(followNotification$ | async)">{{followNotification$ | async}}</span></li>
        <li><a class="hikaye" [routerLink]="['/member/old-stories']" routerLinkActive="active">Hikaye Geçmişi</a></li>
        <li><a class="islem" [routerLink]="['/member/old-operations']" routerLinkActive="active">İşlem Geçmişi</a></li>
        <li><a class="payar" [routerLink]="['/member/profile-settings']" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact:true }">Profil Ayarları</a></li>
        <li><a class="gayar" [routerLink]="['/member/privacy']" routerLinkActive="active">Gizlilik Ayarları</a></li>
        <li class="logout"><a class="cikis" (click)="logout()">Çıkış Yap</a></li>
    </ul>
</div>
