import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthState, Logout } from './auth.state';
import { UtilService } from './util.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  token: string;

  constructor(private store: Store, private utilService: UtilService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const headers = {} as any;
    const token = this.store.selectSnapshot(AuthState.token);
    if (!request.headers.has('Authorization') && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (!request.headers.has('Content-Type') && request.headers.get('accept') !== 'application/json') {
      headers['Content-Type'] = 'application/json';
    }

    return next
      .handle(
        request.clone({
          setHeaders: headers,
        }),
      )
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log(("request");
              // console.log((request);
              if (
                request.method === 'POST' ||
                request.method === 'PATCH' ||
                request.method === 'PUT' ||
                request.method === 'DELETE'
              ) {
                if (event.status === 201 || event.status === 200) {
                  if (
                    !request.urlWithParams.includes('login') &&
                    !request.urlWithParams.includes('guest') &&
                    !request.urlWithParams.includes('seen') &&
                    !request.urlWithParams.includes('money/charge/create') &&
                    !request.urlWithParams.includes('asset/story') &&
                    !request.urlWithParams.includes('like') &&
                    !request.urlWithParams.includes('member/index')
                  ) {
                    // console.log(("event instanceof request");
                    // console.log((event.status);
                    // console.log(("successfull");
                    let successData = {
                      title: 'Başarılı',
                      errorMessage: '',
                      icon: 'success',
                      colorCode: '#26E00B',
                    };
                    //  this.alertService.handleError(errorData);
                    //this.utilService.dataServiceItem.next(successData);
                    return;
                  }
                }
              }
            } else {
              if (
                request.method === 'POST' ||
                request.method === 'PATCH' ||
                request.method === 'PUT' ||
                request.method === 'DELETE'
              ) {
                if (
                  !request.urlWithParams.includes('login') &&
                  !request.urlWithParams.includes('seen') &&
                  !request.urlWithParams.includes('followers') &&
                  !request.urlWithParams.includes('followings')
                ) {
                  let errorData = {
                    title: 'Başarısız',
                    errorMessage: '',
                    icon: 'error',
                    colorCode: '#EE0A0A',
                  };
                  //  this.alertService.handleError(errorData);
                  if (event.type !== 0) {
                    this.utilService.dataServiceItem.next(errorData);
                  }
                  return;
                }
              }
            }
          },
          (error) => {
            if (error?.error?.message instanceof Array) {
              return;
            }
            if (error.status === 401 && !request.url.includes('/auth/login')) {
              localStorage.setItem('guestToken', 'undefined');
              this.store.dispatch(new Logout());
            }
            if (
              ['POST', 'PATCH', 'PUT', 'GET', 'DELETE'].includes(request.method) &&
              [400, 403, 404].includes(error.status) && !request.url.includes('/auth/register')
            ) {
              if (localStorage.getItem('isGuest') === 'true') {
                window.location.href = '/';
              }
              const errorData = {
                title: ' ',
                errorMessage: error?.error?.message,
                icon: 'error',
                colorCode: '#EE0A0A',
              };
              //  this.alertService.handleError(errorData);
              this.utilService.dataServiceItem.next(errorData);
            }
          },
        ),
      );
  }
}
