import { environment } from 'src/environments/environment';
import { UserTypeMapper } from './user-type.mapper';

export class ProfileInfo {

  private static readonly apiUrl = environment.prodUrl;

  public static getProfileInfo(profileData, profileType?: string) {
    let profileInfo = (profileType === 'streamer' || profileType === 'Streamer' || profileType === 'streamers') ? 'Yayıncı' : UserTypeMapper.map(
      profileData?.type?.type || profileData?.type ) + ' Üye';

    if (profileData?.birthDate) {
      profileInfo += `, ${this.findAge(profileData.birthDate, true)}`;
    } else if (profileData?.birthYear) {
      profileInfo += `, ${this.findAge(profileData.birthYear)}`;
    }

    if (profileData?.country) {
      profileInfo += `, ${this.getCountryName(profileData.country)}`;
    }
    return profileInfo;
  }

  public static findAge(value: number | string, isDate?: boolean) {
    if (isDate) {
      const year = new Date(value).getFullYear();
      return new Date().getFullYear() - year;
    } else if (value) {
      let currentDate = new Date().getFullYear();
      return currentDate - (value as number);
    } else {
      return ''
    }
  }

  public static getCountryName(countryCode) {
    if (this.countries.hasOwnProperty(countryCode)) {
      return this.countries[countryCode];
    } else {
      return countryCode;
    }
  }

  public static getPaymentMethod(method) {
    return method === 'transfer' ? 'Banka Havalesi' : 'Western Union';
  }


  // For background url there is a conditional parameter
  public static getProfilePhoto(profileType: string, path: string, isBg?: boolean) {
    let imgPath = ""
    if (path) {
      imgPath = `${path}`
      return isBg ? `url(${imgPath})` : imgPath;
    } else {
      if (profileType === 'streamer' || profileType === 'Streamer' || profileType === 'streamers') {
        imgPath = `./assets/img/Streamer.png`
        return isBg ? `url(${imgPath})` : imgPath;
      } else {
        imgPath = `./assets/img/Member.png`
        return isBg ? `url(${imgPath})` : imgPath;
      }
    }
  }

  private static readonly countries = {
    'TR': 'Türkiye',
    'VI': 'ABD Virgin Adaları',
    'AF': 'Afganistan',
    'AX': 'Aland Adaları',
    'DE': 'Almanya',
    'US': 'Amerika Birleşik Devletleri',
    'UM': 'Amerika Birleşik Devletleri Küçük Dış Adaları',
    'AS': 'Amerikan Samoası',
    'AD': 'Andora',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarktika',
    'AG': 'Antigua ve Barbuda',
    'AR': 'Arjantin',
    'AL': 'Arnavutluk',
    'AW': 'Aruba',
    'QU': 'Avrupa Birliği',
    'AU': 'Avustralya',
    'AT': 'Avusturya',
    'AZ': 'Azerbaycan',
    'BS': 'Bahamalar',
    'BH': 'Bahreyn',
    'BD': 'Bangladeş',
    'BB': 'Barbados',
    'EH': 'Batı Sahara',
    'BZ': 'Belize',
    'BE': 'Belçika',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BY': 'Beyaz Rusya',
    'BT': 'Bhutan',
    'ZZ': 'Bilinmeyen veya Geçersiz Bölge',
    'AE': 'Birleşik Arap Emirlikleri',
    'GB': 'Birleşik Krallık',
    'BO': 'Bolivya',
    'BA': 'Bosna Hersek',
    'BW': 'Botsvana',
    'BV': 'Bouvet Adası',
    'BR': 'Brezilya',
    'BN': 'Brunei',
    'BG': 'Bulgaristan',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Cape Verde',
    'GI': 'Cebelitarık',
    'DZ': 'Cezayir',
    'CX': 'Christmas Adası',
    'DJ': 'Cibuti',
    'CC': 'Cocos Adaları',
    'CK': 'Cook Adaları',
    'TD': 'Çad',
    'CZ': 'Çek Cumhuriyeti',
    'CN': 'Çin',
    'DK': 'Danimarka',
    'DM': 'Dominik',
    'DO': 'Dominik Cumhuriyeti',
    'TL': 'Doğu Timor',
    'EC': 'Ekvator',
    'GQ': 'Ekvator Ginesi',
    'SV': 'El Salvador',
    'ID': 'Endonezya',
    'ER': 'Eritre',
    'AM': 'Ermenistan',
    'EE': 'Estonya',
    'ET': 'Etiyopya',
    'FK': 'Falkland Adaları (Malvinalar)',
    'FO': 'Faroe Adaları',
    'MA': 'Fas',
    'FJ': 'Fiji',
    'CI': 'Fildişi Sahilleri',
    'PH': 'Filipinler',
    'PS': 'Filistin Bölgesi',
    'FI': 'Finlandiya',
    'FR': 'Fransa',
    'GF': 'Fransız Guyanası',
    'TF': 'Fransız Güney Bölgeleri',
    'PF': 'Fransız Polinezyası',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GH': 'Gana',
    'GN': 'Gine',
    'GW': 'Gine-Bissau',
    'GD': 'Granada',
    'GL': 'Grönland',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GY': 'Guyana',
    'ZA': 'Güney Afrika',
    'GS': 'Güney Georgia ve Güney Sandwich Adaları',
    'KR': 'Güney Kore',
    'CY': 'Güney Kıbrıs Rum Kesimi',
    'GE': 'Gürcistan',
    'HT': 'Haiti',
    'HM': 'Heard Adası ve McDonald Adaları',
    'IN': 'Hindistan',
    'IO': 'Hint Okyanusu İngiliz Bölgesi',
    'NL': 'Hollanda',
    'AN': 'Hollanda Antilleri',
    'HN': 'Honduras',
    'HK': 'Hong Kong SAR - Çin',
    'HR': 'Hırvatistan',
    'IQ': 'Irak',
    'VG': 'İngiliz Virgin Adaları',
    'IR': 'İran',
    'IE': 'İrlanda',
    'ES': 'İspanya',
    'IL': 'İsrail',
    'SE': 'İsveç',
    'CH': 'İsviçre',
    'IT': 'İtalya',
    'IS': 'İzlanda',
    'JM': 'Jamaika',
    'JP': 'Japonya',
    'JE': 'Jersey',
    'KH': 'Kamboçya',
    'CM': 'Kamerun',
    'CA': 'Kanada',
    'ME': 'Karadağ',
    'QA': 'Katar',
    'KY': 'Kayman Adaları',
    'KZ': 'Kazakistan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'CO': 'Kolombiya',
    'KM': 'Komorlar',
    'CG': 'Kongo',
    'CD': 'Kongo Demokratik Cumhuriyeti',
    'CR': 'Kosta Rika',
    'KW': 'Kuveyt',
    'KP': 'Kuzey Kore',
    'MP': 'Kuzey Mariana Adaları',
    'CU': 'Küba',
    'KG': 'Kırgızistan',
    'LA': 'Laos',
    'LS': 'Lesotho',
    'LV': 'Letonya',
    'LR': 'Liberya',
    'LY': 'Libya',
    'LI': 'Liechtenstein',
    'LT': 'Litvanya',
    'LB': 'Lübnan',
    'LU': 'Lüksemburg',
    'HU': 'Macaristan',
    'MG': 'Madagaskar',
    'MO': 'Makao S.A.R. Çin',
    'MK': 'Makedonya',
    'MW': 'Malavi',
    'MV': 'Maldivler',
    'MY': 'Malezya',
    'ML': 'Mali',
    'MT': 'Malta',
    'IM': 'Man Adası',
    'MH': 'Marshall Adaları',
    'MQ': 'Martinik',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Meksika',
    'FM': 'Mikronezya Federal Eyaletleri',
    'MD': 'Moldovya Cumhuriyeti',
    'MC': 'Monako',
    'MS': 'Montserrat',
    'MR': 'Moritanya',
    'MZ': 'Mozambik',
    'MN': 'Moğolistan',
    'MM': 'Myanmar',
    'EG': 'Mısır',
    'NA': 'Namibya',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NE': 'Nijer',
    'NG': 'Nijerya',
    'NI': 'Nikaragua',
    'NU': 'Niue',
    'NF': 'Norfolk Adası',
    'NO': 'Norveç',
    'CF': 'Orta Afrika Cumhuriyeti',
    'UZ': 'Özbekistan',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PA': 'Panama',
    'PG': 'Papua Yeni Gine',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PN': 'Pitcairn',
    'PL': 'Polonya',
    'PT': 'Portekiz',
    'PR': 'Porto Riko',
    'RE': 'Reunion',
    'RO': 'Romanya',
    'RW': 'Ruanda',
    'RU': 'Rusya Federasyonu',
    'SH': 'Saint Helena',
    'KN': 'Saint Kitts ve Nevis',
    'LC': 'Saint Lucia',
    'PM': 'Saint Pierre ve Miquelon',
    'VC': 'Saint Vincent ve Grenadinler',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome ve Principe',
    'SN': 'Senegal',
    'SC': 'Seyşeller',
    'SL': 'Sierra Leone',
    'SG': 'Singapur',
    'SK': 'Slovakya',
    'SI': 'Slovenya',
    'SB': 'Solomon Adaları',
    'SO': 'Somali',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Surinam',
    'SY': 'Suriye',
    'SA': 'Suudi Arabistan',
    'SJ': 'Svalbard ve Jan Mayen',
    'SZ': 'Svaziland',
    'RS': 'Sırbistan',
    'CS': 'Sırbistan-Karadağ',
    'CL': 'Şili',
    'TJ': 'Tacikistan',
    'TZ': 'Tanzanya',
    'TH': 'Tayland',
    'TW': 'Tayvan',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad ve Tobago',
    'TN': 'Tunus',
    'TC': 'Turks ve Caicos Adaları',
    'TV': 'Tuvalu',
    'TM': 'Türkmenistan',
    'UG': 'Uganda',
    'UA': 'Ukrayna',
    'OM': 'Umman',
    'UY': 'Uruguay',
    'QO': 'Uzak Okyanusya',
    'JO': 'Ürdün',
    'VU': 'Vanuatu',
    'VA': 'Vatikan',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'WF': 'Wallis ve Futuna',
    'YE': 'Yemen',
    'NC': 'Yeni Kaledonya',
    'NZ': 'Yeni Zelanda',
    'GR': 'Yunanistan',
    'ZM': 'Zambiya',
    'ZW': 'Zimbabve'
  }
}
