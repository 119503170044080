<app-sidebar-streamer></app-sidebar-streamer>
<button type="button" class="navbar-toggle" id="nav_list" data-toggle="collapse" data-target="#myNavbar">
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
</button>
<nav class="navbar">
  <div class="container-fluid">
    <div class="navbar-header">
      <div class="row0">
        <div class="col-xs-6 pl0">
          <a class="navbar-brand" routerLink="/streamer"  ><img alt="" src="./assets/img/logo.png"></a>
        </div>
        <div class="col-xs-6 pr0 closebtn">

          <p class="top-credit">{{streamerData.totalPrice ? streamerData.totalPrice  : 0}} <span>TL</span></p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid"  id="bottom-nav-el">
      <ul class="main-nav mb0">
        <li [ngClass]="{'active':rla1.isActive}"><a routerLink="/streamer" routerLinkActive  #rla1="routerLinkActive"
          [routerLinkActiveOptions]="{ exact:true }"><img alt="" src="./assets/img/home.svg"></a></li>
        <li [ngClass]="{'active':rla2.isActive}"><a routerLink="/streamer/followers" routerLinkActive #rla2="routerLinkActive"><img alt="" src="./assets/img/follow.svg"></a>
          <span *ngIf="follow$|async">{{follow$|async}}</span></li>
        <li [ngClass]="{'active':rla3.isActive}"><a routerLink="/message/box" routerLinkActive #rla3="routerLinkActive" >
          <img alt="" src="./assets/img/message.svg"></a>
          <span *ngIf="(unreadCount$|async)>0">
              {{unreadCount$ | async}}
              </span></li>
        <li [ngClass]="{'active':rla4.isActive}"><a routerLink="/streamer/notifications" routerLinkActive
           #rla4="routerLinkActive" ><img alt="" src="./assets/img/notif.svg"></a>
          <span *ngIf="(follow$|async)||(announcement$|async)">{{(announcement$|async)+(follow$|async)}}</span></li>
      </ul>
      <button class="stream-room" routerLink="/live">YAYIN ODASI</button>
  </div>
</nav>
