import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from './socket.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public selectedUserChat: BehaviorSubject<string> = new BehaviorSubject('');
  public dataServiceItem: BehaviorSubject<string> = new BehaviorSubject('');
  apiUrl = environment.prodUrl;

  constructor(
    private socket: SocketService,
    private http: HttpClient,
  ) {

  }

  getMessages(id, lastAt: any = null) {
    this.socket.emit('getMessages', { conversationId: id, lastAt });
  }

  send(conversationId, message) {
    this.socket.emit('sendMessage', { conversationId, message });
  }

  getConversations() {
    this.socket.emit('getConversations');
  }

  createConversation(ownerId: string, message?: string) {
    this.socket.emit('newConversation', {
      ownerId,
      message,
    });
  }

  sendWriting(conversationId: string) {
    this.socket.emit('writing', conversationId);
  }

  sendRead(conversationId: string, unreads: string[]) {
    this.socket.emit('readMessage', {
      conversationId,
      messageIds: unreads,
    });
  }

  join() {
    this.socket.emit('messageJoin');
    this.socket.getSocket().on('connect',
      () => this.socket.emit('messageJoin'));
  }

  removeConversation(conversationId) {
    return this.http.delete(`${this.apiUrl}message/${conversationId}`);
  }
}
