import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthStateModel } from './model/auth.state.model';
import { Login } from './model/login.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) {}

  signin(email: string, password: string, referer, appType): Observable<AuthStateModel> {
    let sendType = this.isEmail(email) ? 'email' : 'username';
    return this.http.post<Login>(this.apiUrl + 'auth/login', {
      [sendType]: email,
      password: password,
      referer: referer,
      appType: appType,
    });
  }

  guestSigninWithoutToken({ referer, appType }): Observable<AuthStateModel> {
    return this.http.get(this.apiUrl + 'auth/guest');
  }

  socialSignin(accessToken: string, provider: string): Observable<AuthStateModel> {
    return this.http.post<any>(this.apiUrl + 'auth/login-' + provider.toLowerCase(), {
      access_token: accessToken,
    });
  }

  register(
    username: string,
    email: string,
    password: string,
    referer: string,
    appType?: string,
  ): Observable<AuthStateModel> {
    return this.http.post<Login>(this.apiUrl + 'auth/register', {
      username: username,
      email: email,
      password: password,
      referer: referer,
      appType: appType,
    });
  }
  checkInputIsUnique(inputType, inputValue): Observable<object> {
    return this.http.post(this.apiUrl + 'auth/exists', {
      checkFor: inputType,
      searchFor: inputValue,
    });
  }

  changeUsername(username): Observable<object> {
    return this.http.patch(this.apiUrl + 'user/username', {
      username: username,
    });
  }

  logout(type) {
    return of(type);
  }

  isEmail(text) {
    const re = /\S+@\S+\.\S+/;
    return re.test(text);
  }

  resetPassword(obj) {
    return this.http.post(`${this.apiUrl}auth/reset-password-request`, obj);
  }

  setPassword(token, obj) {
    return this.http.post(`${this.apiUrl}auth/reset-password/${token}`, obj);
  }

  sendActivationEmail(email: string): Observable<object> {
    return this.http.post(`${this.apiUrl}auth/send-confirmation`, { email });
  }
}
