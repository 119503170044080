<app-profile-username-warning-bar
*ngIf="headerInfo?.socialProvider && !headerInfo?.socialProvider?.usernameChanged && router.url.includes('/member/index')"
></app-profile-username-warning-bar>
<app-sidebar *ngIf="ownerType && !headerInfo.guestId"></app-sidebar>
<button
  [ngStyle]="{ display: ownerType && !headerInfo.guestId ? 'block' : 'none' }"
  type="button"
  class="navbar-toggle"
  id="nav_list"
  data-toggle="collapse"
  data-target="#myNavbar"
>
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
</button>
<nav class="navbar">
  <div class="container-fluid">
    <div class="navbar-header">
      <div class="row0" [ngClass]="{onlogout: headerInfo.guestId}">
        <div class="col-xs-6 pl0 ">
          <a class="navbar-brand" routerLink="/member/index"><img alt="" src="./assets/img/logo.png" /></a>
        </div>
        <div *ngIf="ownerType && !headerInfo.guestId" class="col-xs-6 pr0 closebtn">
          <p class="top-credit">{{ headerInfo.credit ? headerInfo.credit : 0 }} <span>KR</span></p>
        </div>
        <div *ngIf="headerInfo.guestId" class="member-status">
          <button class="register" (click)="router.navigateByUrl('/auth/login#register')">KAYIT OL</button>
          <button class="signin" (click)="router.navigateByUrl('/auth/login')">GİRİŞ YAP</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="bottom-nav-el">
    <ul class="main-nav">
      <li *ngIf="ownerType && !headerInfo.guestId" [ngClass]="{ active: rla1.isActive }">
        <a [routerLink]="['/member/index']" routerLinkActive #rla1="routerLinkActive"
          ><img alt="" src="./assets/img/home.svg"
        /></a>
      </li>
      <li *ngIf="ownerType && !headerInfo.guestId" [ngClass]="{ active: rla2.isActive }">
        <a [routerLink]="['/member/followers']" routerLinkActive #rla2="routerLinkActive"
          ><img alt="" src="./assets/img/follow.svg"
        /></a>
        <span *ngIf="(followCount$ | async) > 0">{{ followCount$ | async }}</span>
      </li>
      <li *ngIf="ownerType && !headerInfo.guestId" [ngClass]="{ active: rla3.isActive }">
        <a [routerLink]="['/message/box']" routerLinkActive #rla3="routerLinkActive"
          ><img alt="" src="./assets/img/message.svg"
        /></a>
        <span *ngIf="(unreadCount$ | async) > 0">
          {{ unreadCount$ | async }}
        </span>
      </li>
      <li *ngIf="ownerType && !headerInfo.guestId" [ngClass]="{ active: rla4.isActive }">
        <a [routerLink]="['/member/notifications']" routerLinkActive #rla4="routerLinkActive"
          ><img alt="" src="./assets/img/notif.svg"
        /></a>
        <span *ngIf="(followCount$ | async) + (announcementCount$ | async) > 0">{{
          (followCount$ | async) + (announcementCount$ | async)
        }}</span>
      </li>
    </ul>
    <button *ngIf="ownerType && !headerInfo.guestId" class="buy-credit" routerLink="/member/buycredit">
      Kredi Satın Al
    </button>
  </div>
</nav>

