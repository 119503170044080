import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const appVersion = "1.0.0";
const currentVersion = localStorage.getItem('version');

console.log(appVersion, currentVersion);
if (appVersion != currentVersion) {
  localStorage.clear();
  localStorage.setItem('version', appVersion);
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
