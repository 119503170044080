export const splitFloat = function(n) {
  const regex = /(\d*)[.,]{1}(\d*)/;
  var m;

  if ((m = regex.exec(n.toString())) !== null) {
    return {
      integer: m[1],
      decimal: m[2],
    };
  }

  return {
    integer: n,
    decimal: 0,
  }
};

export const typeMap = {
  'public-stream': 'Genel Yayın',
  'private-stream': 'Birebir Görüşme',
  'streamer-commission': 'Yayıncı Komisyonu',
  'user-commission': 'Üye Komisyonu',
  'admin-commission': 'Admin Komisyonu',
  'gift': 'Hediye',
  'advance': 'Avans',
}


export const isMobileIOS = () => {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
}