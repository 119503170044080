import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthState } from 'src/app/store/auth.state';
import { Select, Store } from '@ngxs/store';
import { UserStateModel } from 'src/app/store/model/user.state.model';
import { UserState } from 'src/app/store/user.state';
import { Observable, Subject } from 'rxjs';
import { StreamerState } from 'src/app/store/streamer.state';
import { takeUntil } from 'rxjs/operators';

import { StreamerStateModel } from 'src/app/store/model/streamer.state.model';

declare var LiveChatWidget: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<UserStateModel>;
  @Select(StreamerState.getUser) streamer$: Observable<StreamerStateModel>;
  currentDate = new Date();
  _unsubscribeAll = new Subject()
  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    const ownerType = this.store.selectSnapshot(AuthState.ownerType);
    if (ownerType === 'Streamer') {
      this.streamer$.pipe(takeUntil(this._unsubscribeAll)).subscribe((streamerData) => {
        if (streamerData && streamerData.username) {
          LiveChatWidget.call('set_customer_name', streamerData.streamerName);

          LiveChatWidget.call('set_session_variables', {
            'Kullanici Tipi': 'YAYINCI',
            ID: streamerData.streamerId,
            'Kullanici Adi': streamerData.username,
            'Yayinci Adi': streamerData.streamerName,
            'Email Onay Durumu': streamerData.emailVerified ? 'Onaylandi' : 'Onaylanmadi',
            Email: streamerData.email,
          });
        }
      });
    } else {
      this.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userData) => {
        if (userData && userData.username) {
          LiveChatWidget.call('set_customer_name', userData.username);
          LiveChatWidget.call('set_session_variables', {
            'Kullanici Tipi': userData.type?.type,
            'Kullanici Adi': userData.username,
            'Email Onay Durumu': userData.emailVerified ? 'Onaylandi' : 'Onaylanmadi',
            Email: userData.email,
            ID: userData._id,
            'Kayit Traihi': userData.createdAt,
            Kredi: userData.credit,
            'Toplam Kredi': userData.totalCredit,
          });
        }
      });
    }
    if (this.router.url.includes('/live')) {
      LiveChatWidget.call('hide');
    }
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        if (data.url.includes('/live')) {
          LiveChatWidget.call('hide');
        }
      }
    });
  }

  toggleChatWidget() {
    LiveChatWidget.call('maximize');
  }
}
