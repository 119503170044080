import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Logout } from 'src/app/store/auth.state';
import { MemberService } from 'src/app/store/member.service';
import { StreamerStateModel } from 'src/app/store/model/streamer.state.model';
import { NotificationState, GetNotifications } from 'src/app/store/notification.state';
import { SharedService } from 'src/app/store/shared.service';
import { StreamerState } from 'src/app/store/streamer.state';
import { environment } from 'src/app/../environments/environment';
import { splitFloat } from 'src/app/utils/utils';
import { StreamerTypeMapper } from 'src/app/utils/streamer-type.mapper';
import { ProfileInfo } from 'src/app/utils/profile-info';
import { WalletService } from 'src/app/store/wallet.service';
import { take, takeUntil } from 'rxjs/operators';

declare var $;

@Component({
  selector: 'app-sidebar-streamer',
  templateUrl: './sidebar-streamer.component.html',
  styleUrls: ['./sidebar-streamer.component.css'],
})
export class SidebarStreamerComponent implements OnInit {
  @Select(StreamerState.getUser) streamer$: Observable<StreamerStateModel>;
  @Select(NotificationState.getAnnouncementNotificationCount) announcement$: Observable<number>;
  @Select(NotificationState.getFollowNotificationsCount) follow$: Observable<number>;
  totalOnlineSeconds: number;
  totalOnlineLeftOverMinutes: number;
  totalOnlineHours: number;
  apiUrl = environment.prodUrl;
  streamerData;
  messages = 0;
  followerCount = 0;
  followingCount = 0;
  totalPrice: { integer: string; decimal: string };
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private sharedService: SharedService,
    private memberService: MemberService,
    private walletService: WalletService,
  ) {}

  ngOnInit(): void {
    this.loadjQuery();
    this.streamer$.pipe(take(4)).subscribe((data) => {
      if (data.totalPrice && (!this.streamerData || this.streamerData.streamerId !== data.streamerId)) {
        this.streamerData = data;
        this.store.dispatch(new GetNotifications());
        const price = data?.totalPrice || 0;
        this.totalPrice = splitFloat(price);
        this.getUnreadMessages();
        this.getFollowings();
        this.getFollowers();
      }
    });
    this.store.select(StreamerState.getTotalWeeklyStreamingSeconds).subscribe(async (totalWeeklyStreamingSeconds) => {
      if (this.streamerData) {
        this.totalOnlineSeconds = totalWeeklyStreamingSeconds || 0;
        this.setTotalOnlineHours();
        this.setTotalOnlineMinutes();
      }
    });
  }

  getFollowers() {
    this.memberService.getFollowers().pipe(take(1)).subscribe((data) => {
      this.followerCount = data['totalDocs'];
    });
  }

  getFollowings() {
    this.memberService.getFollowings().pipe(take(1)).subscribe((data) => {
      this.followingCount = data['totalDocs'];
    });
  }

  getUnreadMessages() {
    this.sharedService.conversations.subscribe((conversations) => {
      this.messages = conversations['docs'].forEach(
        (conversation) => (this.messages += conversation['streamerUnreadCount']),
      );
    });
  }

  profilePhoto(path) {
    return ProfileInfo.getProfilePhoto('streamer', path, true);
  }

  getStreamerType(type) {
    return StreamerTypeMapper.map(type);
  }

  logout() {
    $('body').removeClass('oh');
    this.store.dispatch(new Logout());
  }
  loadjQuery() {
    $(document).ready(function () {
      $(' ul.left-list li a').on('click', function () {
        $('.pushmenu').removeClass('pushmenu-open');
        $('.menu-overlay').removeClass('showoverlay');
        $('#nav_list').removeClass('closebutton');
        $('#nav_list').removeClass('activex');
        $('body').removeClass('oh');
      });
    });
  }
  setTotalOnlineHours() {
    this.totalOnlineHours = Math.floor(this.totalOnlineSeconds / 3600) || 0;
  }

  setTotalOnlineMinutes() {
    this.totalOnlineLeftOverMinutes = Math.floor((Number(this.totalOnlineSeconds) / 60) % 60 || 0);
  }
}
