import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WalletStateModel } from './model/wallet.state.model';
import { WalletService } from './wallet.service';
import { tap } from 'rxjs/operators';

export class GetWallet {
  static readonly type = '[Wallet] GetWallet';
  constructor() {}
}
export class ChangeWalletState {
  static readonly type = '[Wallet] ChangeWalletState';
  constructor(public wallet: WalletStateModel) {}
}

@State<WalletStateModel>({
  name: 'wallet',
})
@Injectable()
export class WalletState {
  @Selector()
  static getWalletState(state: WalletStateModel): WalletStateModel {
    return state;
  }

  constructor(private walletService: WalletService) {}

  @Action(GetWallet)
  gettTotalWeeklyStreamingSeconds({ patchState }: StateContext<WalletStateModel>) {
    return this.walletService.cumulativeBalance().pipe(
      tap((response: WalletStateModel) => {
        patchState({
          audioCredit: response?.audioCredit || 0,
          audioPrice: response?.audioPrice || 0,
          commissionCredit: response?.commissionCredit || 0,
          commissionPrice: response?.commissionPrice || 0,
          giftCredit: response?.giftCredit || 0,
          giftPrice: response?.giftPrice || 0,
          privateStream: response?.privateStream || 0,
          privateStreamPrice: response?.privateStreamPrice || 0,
          publicStream: response?.publicStream || 0,
          publicStreamPrice: response?.publicStreamPrice || 0,
          totalCredit: response?.totalCredit || 0,
          totalPrice: response?.totalPrice || 0,
          totalWeeklyStreamingSeconds: response?.totalWeeklyStreamingSeconds || 0,
        });
      }),
    );
  }
  @Action(ChangeWalletState)
  getTotalWeeklyStreamingSeconds(ctx: StateContext<WalletStateModel>,  wallet ) {
    ctx.patchState(wallet.wallet);
  }
}
