import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  prodUrl = environment.prodUrl;

  constructor(private http: HttpClient) {}

  getUser(): Observable<any> {
    return this.http.get(`${this.prodUrl}user`);
  }

  getBuddys() {
    return this.http.get(`${this.prodUrl}users/buddys`);
  }
  patchUser(user): Observable<any> {
    return this.http.patch(`${this.prodUrl}user`, user.user);
  }
  saveUser(obj) {
    return this.http.patch(`${this.prodUrl}user`, obj);
  }
}
