import { ConversationStateModel } from './model/conversation.state.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class GetConversation {
  static readonly type = '[Conversation] GetConversation';

  constructor(public conversationState: ConversationStateModel) { }
}

export class SetUnreadCount {
  static readonly type = '[Conversation] SetUnreadCount';

  constructor(public count: number) { }
}

export class IncUnreadCount {
  static readonly type = '[Conversation] IncUnreadCount';

  constructor(public inc: number = 1) { }
}

@State<ConversationStateModel>({
  name: 'conversation',
})
@Injectable()
export class ConversationState {
  @Selector()
  static getConversation(state: ConversationStateModel) {
    return state;
  }

  @Selector()
  static unreadCount({ unreadCount }: ConversationStateModel) {
    return unreadCount;
  }

  @Action(GetConversation)
  getConversation({ patchState }: StateContext<ConversationStateModel>,
                  { conversationState }: GetConversation) {
    return patchState({
      conversation: conversationState,
    });
  }

  @Action(SetUnreadCount)
  setUnreadCount({ patchState }: StateContext<ConversationStateModel>,
                 { count }: SetUnreadCount) {
    return patchState({
      unreadCount: count,
    });
  }

  @Action(IncUnreadCount)
  incUnreadCount({ patchState, getState }: StateContext<ConversationStateModel>,
                 { inc }: IncUnreadCount) {
    return patchState({
      unreadCount: getState().unreadCount + inc,
    });
  }
}
