export enum ChatChannelEnum {
  JOIN = 'chatJoin',
  LEAVE = 'chatLeave',
  BAN = 'chatBan',
  MESSAGE = 'chatMessage',
  INVITE = 'privateChatInvite',
  INFO = 'chatInfo',
  PARTICIPANTS = 'chatParticipants',
  JOINED = 'chatJoined',
  MANAGE = 'chatManage',
  SENT = 'sentChatMessage',
  STORIES = 'stories',
  USERINCHAT = 'userInChat',
  STREAMERINCHAT = 'streamerInChat',
}
