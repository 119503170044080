export const environment = {
  production: false,
  prodUrl: 'https://api.cafecanli.biz/',
  socketUrl: 'https://api.cafecanli.biz',
  janusUrl: 'wss://media.cafecanli.biz',
  google_client_id: '269788945797-f29pgcd8lvsnf4ujnoa7940bldmd3uc2.apps.googleusercontent.com',
  facebook_client_id: '371954003522194',
  appType: 'Cafecanli M.',
  stripe_pub_key: 'pk_test_de5wUSeUl7LeIvza8ASGLZ2y',
};
