import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserStateModel } from './model/user.state.model';
import { UserService } from './user.service';
import { tap,catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export class GetUser {
  static readonly type = '[User] GetUser';
  constructor() {}
}
export class PatchUser {
  static readonly type = '[User] PatchUser';
  constructor(public user) {}
}
export class Spent {
  static readonly type = '[User] Spent';
  constructor(public credit: number) {}
}

export class SetUserProfile {
  static readonly type = '[User] Set Profile';
  constructor(public profile: any) {}
}

@State<UserStateModel>({
  name: 'user',
})
@Injectable()
export class UserState {
  @Selector()
  static getUser(state: UserStateModel) {
    return state;
  }

  constructor(private userService: UserService) {}

  @Action(GetUser)
  getUser({ patchState,dispatch }: StateContext<UserStateModel>) {
    return this.userService.getUser().pipe(
      tap((response: UserStateModel) => {
        patchState({
          _id: response._id,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt,
          email: response.email,
          emailVerified: response.emailVerified,
          status: response.status,
          username: response.username,
          parentStreamer: response.parentStreamer,
          name: response.name,
          type: response.type,
          country: response.country,
          lang: response.lang,
          birthYear: response.birthYear,
          privacy: response.privacy,
          gender: response.gender,
          profile: response.profile,
          penalty: response.penalty,
          credit: response.credit,
          totalCredit: response.totalCredit,
          guestId :response.guestId,
          socialProvider: response.socialProvider,
          registeredInfo: response.registeredInfo
        });
      }),
      catchError(err => {
        return of('error', err)
      })
    )
  }
  @Action(PatchUser)
  patchUser({ patchState, getState }: StateContext<UserStateModel>, user) {
    return this.userService.patchUser(user).pipe(
      catchError(err => {
        return of('error', err)
      })
    )
  }
  @Action(Spent)
  spent({ patchState, getState }: StateContext<UserStateModel>, { credit }: Spent) {
    const currentCredit = getState()?.credit || 0;
    patchState({
      credit: currentCredit - credit,
    });
  }

  @Action(SetUserProfile)
  setProfile({ patchState }: StateContext<UserStateModel>, { profile }: SetUserProfile) {
    return patchState({ profile });
  }
}
