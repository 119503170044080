import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) {
  }

  public cumulativeBalance(): Observable<any> {
    return this.http.get(`${this.apiUrl}money/earnings/weekly`);
  }

  public getCommission (obj: any): Observable<any> {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj[key]) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach(i => {
            params.append(key, i);
          });
        } else {
          params.append(key, obj[key]);
        }
      }
    }
    params.append('sort', 'createdAt:desc');

    return this.http.get(`${this.apiUrl}money/earnings/commissions?${params}`);
  }

  public dailyBalance(): Observable<any> {
    return this.http.get(`${this.apiUrl}money/earnings/daily`);
  }

  public mostNewBalance(obj): Observable<any> {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}money/earnings?${params}`);
  }

  public latestPayments(obj): Observable<any> {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}money/payments?${params}`);
  }

  public settings(): Observable<any> {
    return this.http.get(`${this.apiUrl}settings`);
  }
}
