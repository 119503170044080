import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthState } from './store/auth.state';
import { Observable } from 'rxjs';
import { SocketService } from './store/socket.service';
import { SEOService } from './store/seo.service';
import { filter } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'cafecanli-mobil';
  @Select(AuthState.token) token$: Observable<string>;

  constructor(
    private readonly socketService: SocketService,
    private store: Store,
    private location: Location,
    public seoService: SEOService,
  ) {
    const url = new URL(window.location.href);
    let referer = url.searchParams.get('r');
    if (!referer && !sessionStorage.getItem('referer')) {
      referer = document.referrer;
    }
    if (referer) {
      sessionStorage.setItem('referer', referer);
    }
    const ownerType = this.store.selectSnapshot(AuthState.ownerType);

    this.seoService.updateTitle();
  }

  ngOnInit() {
    this.token$.pipe(filter((token) => !!token)).subscribe((token) => {
      this.socketService.connect(token);
    });
  }
}
