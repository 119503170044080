<div class="warning-bar username">
  <div class="modal-action" (click)="openUsernameModal()">
    Geçici kullanıcı adı kullanıyorsunuz. Lütfen
    <u (click)="openUsernameModal()" style="margin: 0 5px; cursor: pointer"> buraya tıklayarak </u>
    kullanıcı adınızı değiştirin.
  </div>
  <ngx-smart-modal #usernameModal identifier="usernameModal" [closable]="true" [dismissable]="false">
    <div class="username-form">
      <form [formGroup]="usernameForm">
        <input
          class="funame"
          formControlName="username"
          placeholder="Kullanıcı Adı"
          type="text"
          name="username"
          (click)="usernameError = ''"
          type="text"
          maxlength="15"
          value=""
          (input)="isValidUsernameCharacters($event)"
        />
        <img
          [style]="{
            display: usernameImg ? 'block' : 'none',
            width: usernameImg && usernameImg.includes('valid') ? '14px' : '11px'
          }"
          style="margin-left: -20px"
          class="verfyinput"
          [src]="usernameImg"
        />
        <button [disabled]="disableSendButton" class="send-button" type="submit" (click)="changeUsername()">
          GÖNDER
        </button>
      </form>
    </div>
  </ngx-smart-modal>
</div>
