import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AuthState, Login, Register, SocialLogin } from '../../store/auth.state';
import { Navigate } from '@ngxs/router-plugin';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthService } from '../../store/auth.service';
import Swal from 'sweetalert2';
import { GetStreamer } from 'src/app/store/streamer.state';
import { GetUser } from 'src/app/store/user.state';
import { environment } from '../../../environments/environment';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  @ViewChild('videoFileContainer', { static: false }) videoFileContainer;
  landingErrors: string;
  forgotPasswordError: string;
  sentEmailSuccessfully = false;
  landingForm: FormGroup;
  usernameImg: string;
  emailImg: string;
  public currentVideoOrder = 1;
  public streamerNames = ['Selinay', 'Pelin', 'Ece', 'Buse'];
  public landingPopup = false;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.landingForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    const data = this.store.selectSnapshot(AuthState.token);
    if (!data) {
      this.landingPopup = true;
    } else {
      const ownerType = this.store.selectSnapshot(AuthState.ownerType);
      if (ownerType === 'Streamer') {
        this.store.dispatch(new Navigate(['/streamer']));
      } else {
        this.store.dispatch(new Navigate(['/member/index']));
      }
    }

    this.socialAuthService.authState.subscribe((user) => {
      // console.log('social', user);

      this.store.dispatch(new SocialLogin(user.authToken, user.provider)).subscribe(
        (success) => {
          const ownerType = this.store.selectSnapshot(AuthState.ownerType);
          if (ownerType === 'Streamer') {
            this.store.dispatch(new Navigate(['/streamer']));
          } else {
            this.store.dispatch(new Navigate(['/member/index']));
          }
        },
        (error) => {
          // console.log(error);
        },
      );
    });
    document.getElementById('video1').addEventListener('ended', () => this.playNextVideo());
  }

  playNextVideo() {
    if (this.currentVideoOrder === 4) {
      this.currentVideoOrder = 1;
    } else {
      this.currentVideoOrder += 1;
    }
    this.videoFileContainer.nativeElement.load();
    this.videoFileContainer.nativeElement.play();
  }
  playPrevVideo() {
    if (this.currentVideoOrder === 1) {
      this.currentVideoOrder = 4;
    } else {
      this.currentVideoOrder -= 1;
    }
    this.videoFileContainer.nativeElement.load();
    this.videoFileContainer.nativeElement.play();
  }

  checkIfUserNameExist() {
    if (this.landingForm.controls.username.valid) {
      this.authService.checkInputIsUnique('USERNAME', this.landingForm.value['username']).subscribe(
        (response) => {
          console.log(response);
          this.usernameImg = 'assets/img/valid.svg';
        },
        (error) => {
          this.usernameImg = 'assets/img/error.svg';
          const data = {
            title: 'Kullanıcı Adı Hatası',
            icon: 'error',
            text: 'Kullanıcı adı daha önce alınmış. Lütfen başka kullanıcı adı seçiniz',
          };
          this.alertPopup(data);
        },
      );
    } else {
      this.usernameImg = 'assets/img/error.svg';
    }
  }
  register(): void {
    const uniqMail = `${window.Date.now().valueOf().toString()}@cafecanli.com`;
    this.store
      .dispatch(
        new Register(
          this.landingForm.value.username,
          uniqMail,
          this.landingForm.value.password,
          sessionStorage.getItem('referer') || '',
          environment.appType,
        ),
      )
      .subscribe(
        (success) => {
          this.store.dispatch(new Navigate(['/member/index']));
        },
        (error) => {
          // console.log(error.error.message);
          const data = {
            title: 'Kayıt oluşturulamadı',
            text: 'Lütfen hatalı alanları düzeltin.',
            icon: 'error',
          };
          this.alertPopup(data);
        },
      );
  }

  changeLogin(): void {
    this.landingPopup = !this.landingPopup;
  }

  signInWithGoogle(): void {
    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  isValidPassword(): string {
    const passwordRegex = /^(?=.{8,}$)(?=[^A-Za-z]*[A-Za-z])(?=[^0-9]*[0-9])(?!.*(.)\1{4})/g;
    if (this.landingForm.controls.password.valid && passwordRegex.test(this.landingForm.value['password'])) {
      return 'assets/img/valid.svg';
    }
    return 'assets/img/error.svg';
  }

  alertPopup(data): void {
    Swal.fire({
      title: data.title,
      text: data.text,
      icon: data.icon,
      confirmButtonText: 'Tamam',
    });
  }
}
