import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public dataServiceItem: BehaviorSubject<{}> = new BehaviorSubject({});
  constructor() { }
}
