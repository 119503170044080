import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthStateModel } from './model/auth.state.model';
import { AuthService } from './auth.service';
import { Navigate } from '@ngxs/router-plugin';
import { GetUser } from './user.state';
import { environment } from 'src/environments/environment';

export class SocialLogin {
  static readonly type = '[Auth] SocialLogin';

  constructor(public accessToken, public provider) {}
}

export class SocialLoginWithUrl {
  static readonly type = '[Auth] SocialLoginWithUrl';

  constructor(public loginResponse) {}
}
export class SetUser {
  static readonly type = '[Auth] Set User';

  constructor(
    public accessToken: string,
    public name: string,
    public email: string,
    public ownerType: string,
  ) {}
}
export class SetSocialToken {
  static readonly type = '[Auth] Set Social Token';

  constructor(public token: string) {}
}
export class Login {
  static readonly type = '[Auth] Login';

  constructor(public email: string, public password: string, public referer?: string, public appType?: string) {}
}
export class GuestLogin {
  static readonly type = '[Auth] GuestLogin';

  constructor(public token: string, public referer?: string, public appType?: string) {}
}
export class SetGuestToken {
  static readonly type = '[Auth] Set Guest Token';

  constructor(public token: string) {}
}
export class Register {
  static readonly type = '[Auth] Register';

  constructor(
    public username: string,
    public email: string,
    public password: string,
    public referer?: string,
    public appType?: string,
  ) {}
}

export class SetSoundOptions {
  static readonly type = '[Auth] Set Sound';

  constructor(public messageSoundOn: boolean) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    messageSoundOn: true,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static refreshToken(state: AuthStateModel) {
    // state.refreshToken;
  }

  @Selector()
  static token(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static ownerType(state: AuthStateModel) {
    // console.log(("AUTH:", state);
    return state.ownerType;
  }

  @Selector()
  static userDetails(state: AuthStateModel) {
    return {
      name: state.name,
      email: state.email,
    };
  }

  @Selector()
  static messageSoundOn(state: AuthStateModel) {
    return state.messageSoundOn;
  }

  constructor(private authService: AuthService) {}

  @Action(SocialLogin)
  socialLogin({ patchState }: StateContext<AuthStateModel>, { accessToken, provider }: SocialLogin) {
    return this.authService.socialSignin(accessToken, provider).pipe(
      tap((response) => {
        patchState({
          accessToken: response.accessToken,
          name: response.name,
          email: response.email,
          ownerType: response.ownerType,
        });
      }),
    );
  }
  @Action(SetUser)
  setUser(
    { patchState }: StateContext<AuthStateModel>,
    { email, name, accessToken, ownerType }: any
  ) {
    patchState({
      accessToken,
      name,
      email,
      ownerType,
    });
  }
  @Action(SetSocialToken)
  setSocialToken(
    { patchState }: StateContext<AuthStateModel>,
    { token }: SetSocialToken
  ) {
    return patchState({ accessToken: token, ownerType: 'User' });
  }
  @Action(SocialLoginWithUrl)
  socialLoginUrl({ patchState }: StateContext<AuthStateModel>, { loginResponse }: SocialLoginWithUrl) {
    patchState({
      accessToken: loginResponse.accessToken,
      name: loginResponse.name,
      email: loginResponse.email,
      ownerType: loginResponse.ownerType,
    });
  }

  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, { email, password, referer, appType }: Login) {
    return this.authService.signin(email, password, referer, appType).pipe(
      tap((response) => {
        patchState({
          accessToken: response.accessToken,
          name: response.name,
          email: response.email,
          ownerType: response.ownerType,
        });
      }),
    );
  }

  @Action(GuestLogin)
  guestLogin({ patchState }: StateContext<AuthStateModel>, { referer, appType }: GuestLogin) {
    return this.authService.guestSigninWithoutToken({ referer, appType }).pipe(
      tap((response) => {
        patchState({
          accessToken: response.accessToken,
          ownerType: 'Guest',
        });
      }),
    );
  }
  @Action(SetGuestToken)
  setGuestToken({ patchState }: StateContext<AuthStateModel>, { token }: SetGuestToken) {
    return patchState({ accessToken: token });
  }

  @Action(Register)
  register({ patchState }: StateContext<AuthStateModel>, { username, email, password, referer, appType }: Register) {
    return this.authService.register(username, email, password, referer, appType).pipe(
      tap((response) => {
        patchState({
          accessToken: response.accessToken,
          name: response.name,
          email: response.email,
          ownerType: response.ownerType,
        });
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authService.logout(state.accessToken).pipe(
      tap(() => {
        ctx.setState({
          accessToken: null,
          name: null,
          email: null,
          ownerType: null,
        });
        localStorage.setItem('isGuest', 'true');
        
        ctx.dispatch(new SetGuestToken(null));
        window.location.reload();
        //ctx.dispatch(new Navigate(['member/index']));
        return false;
      }),
    );
  }

  @Action(SetSoundOptions)
  setUserSoundOptions({ patchState }: StateContext<AuthStateModel>, { messageSoundOn }: SetSoundOptions) {
    return patchState({ messageSoundOn });
  }
}
