import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MemberService } from './member.service';
import { NotificationStateModel } from './model/notification.state.model';

export class GetNotifications {
  static readonly type = '[Notification] GetNotification';
  constructor() {}
}

@State<NotificationStateModel>({
  name: 'notification',
  defaults: null,
})
@Injectable()
export class NotificationState {
  @Selector()
  static getFollowNotificationsCount(state: NotificationStateModel) {
    let total = 0;
    state.notifications.forEach((notification) => (total += notification['itemType'] === 'Follow' ? 1 : 0));
    return total;
  }

  @Selector()
  static getAnnouncementNotificationCount(state: NotificationStateModel) {
    let total = 0;
    state.notifications.forEach(
      (notification) => (total += notification['itemType'] === 'Announcement' && notification['item'] !== null ? 1 : 0),
    );
    return total;
  }

  @Selector()
  static getNotification(state: NotificationStateModel) {
    return state;
  }

  constructor(private memberService: MemberService) {}

  @Action(GetNotifications)
  getNotifications({ patchState }: StateContext<NotificationStateModel>) {
    return this.memberService.getNotifications({ justUnseen: true }).pipe(
      tap((response: any) => {
        patchState({ notifications: response.docs });
      }),
    );
  }
}
