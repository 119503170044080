import { filter } from 'rxjs/operators';
import { StatusColorPipe } from './../pipes/status-color.pipe';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.service';
import { ChatChannelEnum } from '../store/enum/chat-channel.enum';
import { ChatInfoDataInterface, ChatInviteDataInterface, ChatLeaveDataInterface } from './chat.service';
import { SetPrivateStreamers, SetPublicStreamers } from './stream.state';
import { Store } from '@ngxs/store';
import { Spent } from './user.state';
import { Earned } from './streamer.state';
import { Logout } from './auth.state';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _socket: Socket;
  private _connectedWithToken: boolean = false;

  constructor(private readonly sharedService: SharedService, private readonly store: Store) {}

  connect(token?: string) {
    // that means we have a connection with a token so we dont need connect again
    // if(this._connectedWithToken && token){
    //   return;
    // }
    if (this._socket) {
      this._socket.disconnect();
    }
    this._socket = new Socket({
      url: token ? environment.socketUrl + '?token=' + token : environment.socketUrl,
      options: { transports: ['websocket'] },
    });
    this._subscribePublicEvents();

    if (token) {
      this._subscribe();

      this._connectedWithToken = true;
    }
    this.sharedService.streams.subscribe((streams) => {
      this.store.dispatch(new SetPublicStreamers(streams?.filter((s) => s.type === 'public')?.map((s) => s.streamer)));
      this.store.dispatch(
        new SetPrivateStreamers(streams?.filter((s) => s.type === 'private')?.map((s) => s.streamer)),
      );
    });

    this.sharedService.spendings.subscribe((credit) => this.store.dispatch(new Spent(credit)));
    this.sharedService.earnings.subscribe((credit) => this.store.dispatch(new Earned(credit)));
    this.sharedService.login.subscribe((_) => this.store.dispatch(new Logout()));
    this._socket.on('connect', () =>
      this.sharedService.socketConnected.next(true)
    );
  }

  getSocket(): Socket {
    return this._socket;
  }

  emit(event: string, data?: any) {
    this._socket.emit(event, data);
  }

  private _subscribe() {
    this.sharedService.conversations = this._socket.fromEvent<object[]>('conversations');
    this.sharedService.messages = this._socket.fromEvent<object[]>('messages');
    this.sharedService.newMessage = this._socket.fromEvent<object[]>('newMessage');
    this.sharedService.writing = this._socket.fromEvent<object[]>('writing');
    this.sharedService.newConversation = this._socket.fromEvent<object[]>('newConversation');
    this.sharedService.sentMessage = this._socket.fromEvent<object[]>('sentMessage');
    this.sharedService.messageJoined = this._socket.fromEvent<object[]>('messageJoined');
    this.sharedService.readMessage = this._socket.fromEvent<object[]>('readMessage');

    this.sharedService.chatParticipants = this._socket.fromEvent<object[]>(ChatChannelEnum.PARTICIPANTS);
    this.sharedService.chatMessage = this._socket.fromEvent<object[]>(ChatChannelEnum.MESSAGE);
    this.sharedService.chatJoined = this._socket.fromEvent<object[]>(ChatChannelEnum.JOINED);
    this.sharedService.chatInvite = this._socket.fromEvent<ChatInviteDataInterface>(ChatChannelEnum.INVITE);
    this.sharedService.chatLeave = this._socket.fromEvent<ChatLeaveDataInterface>(ChatChannelEnum.LEAVE);
    this.sharedService.chatInfo = this._socket.fromEvent<ChatInfoDataInterface>(ChatChannelEnum.INFO);

    this.sharedService.newNotification = this._socket.fromEvent<object>('notification');

    this.sharedService.stories = this._socket.fromEvent<object[]>('stories');
    this.sharedService.earnings = this._socket.fromEvent<number>('earnings');
    this.sharedService.spendings = this._socket.fromEvent<number>('spendings');
    this.sharedService.exception = this._socket.fromEvent<object>('exception');
    this.sharedService.login = this._socket.fromEvent<boolean>('login');
  }

  private _subscribePublicEvents() {
    this.sharedService.userStatus = this._socket.fromEvent('userStatus');
    this.sharedService.streams = this._socket.fromEvent('streams');

    this.sharedService.streams.pipe(filter((streams) => !!streams && streams?.length > 0)).subscribe((streams) => {
      StatusColorPipe.streamers = streams?.reduce((acc, next) => {
        acc[next.streamer._id] = next.type;
        return acc;
      }, {});
    });

    this.sharedService.userStatus.subscribe((status) => {
      if (Array.isArray(status)) {
        status.forEach((s) => {
          StatusColorPipe.statuses[s.ownerId] = s.online;
        });
      } else {
        if (status.online) {
          StatusColorPipe.statuses[status.ownerId] = status.online;
        } else {
          delete StatusColorPipe.statuses[status.ownerId];
        }
      }
    });
  }
}
