import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthState, Logout } from 'src/app/store/auth.state';
import { MemberService } from 'src/app/store/member.service';
import { UserStateModel } from 'src/app/store/model/user.state.model';
import { GetNotifications, NotificationState } from 'src/app/store/notification.state';
import { SharedService } from 'src/app/store/shared.service';
import { UserState } from 'src/app/store/user.state';
import { ProfileInfo } from 'src/app/utils/profile-info';
import { UserTypeMapper } from 'src/app/utils/user-type.mapper';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  sideBarInfo;
  ownerType;
  fileToUpload: File = null;
  followerCount;
  followingCount;
  userId;
  notificationsFollowCount = 0;
  notificationsCount = 0;
  messages = 0;
  _unsubscribeAll: Subject<boolean> = new Subject();
  public cropModal: boolean = false;
  apiUrl = environment.prodUrl;

  @Select(UserState.getUser) user$: Observable<UserStateModel>;
  @Select(NotificationState.getFollowNotificationsCount) followNotification$: Observable<number>;
  @Select(NotificationState.getAnnouncementNotificationCount) announcementNotification$: Observable<number>;

  constructor(private store: Store, private sharedService: SharedService, private memberService: MemberService) {}

  ngOnInit(): void {
    this.loadjQuery();
    this.ownerType = this.store.selectSnapshot(AuthState.ownerType);
    this.user$.pipe(take(4)).subscribe((data) => {
      if (data._id && !data.guestId && this.ownerType !== 'Guest') {
        this.sideBarInfo = data;
        this.userId = data._id;
        this.store.dispatch(new GetNotifications());
        this.getFollowers();
        this.getFollowings();
        this.getUnreadMessages();
      }
    });
  }
  
  getUnreadMessages() {
    this.sharedService.conversations.pipe(take(1)).subscribe((conversations) => {
      this.messages = (conversations as any).docs.forEach(
        (conversation) => (this.messages += conversation['userUnreadCount']),
      );
    });
  }

  getUserType(type: string) {
    return UserTypeMapper.map(type);
  }

  findRestOfTheDaysFromEnd(endDate) {
    const firstDate = new Date();
    const secondDate = new Date(endDate);
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(firstDate.getTime() - secondDate.getTime());
    // Convert back to days and return
    // console.log(secondDate);
    return Math.round(differenceMs / ONE_DAY);
  }

  findPathByType() {
    if (this.ownerType === 'Streamer') {
      return `/streamer`;
    }
    return `/member`;
  }

  getFollowers() {
    this.memberService.getFollowers().pipe(take(1)).subscribe((data) => {
      this.followerCount = data['totalDocs'];
    });
  }

  getFollowings() {
    this.memberService.getFollowings().pipe(take(1)).subscribe((data) => {
      this.followingCount = data['totalDocs'];
    });
  }

  profilePhoto(path) {
    return ProfileInfo.getProfilePhoto('user', path, true);
  }

  logout() {
    $('body').removeClass('oh');
    this.store.dispatch(new Logout());
  }

  loadjQuery() {
    $(document).ready(function () {
      $(' ul.left-list li a').on('click', function () {
        $('.pushmenu').removeClass('pushmenu-open');
        $('.menu-overlay').removeClass('showoverlay');
        $('#nav_list').removeClass('closebutton');
        $('#nav_list').removeClass('activex');
        $('body').removeClass('oh');
      });
    });
  }
}