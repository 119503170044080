<div class="menu-overlay"></div>
<div class="pushmenu pushmenu-left">
  <div class="menu-head-ctn">
    <div class="menu-head" [ngStyle]="{ 'background-image': profilePhoto(streamerData?.profile?.picture?.path) }"></div>
  </div>
  <div class="nav-info">
    <div class="nav-info-left">
      <p class="follower-count">{{ followerCount > 0 ? followerCount : 0 }}</p>
      <p class="follower-title">TAKİPÇİ</p>
    </div>
    <div class="nav-info-right">
      <p class="follower-count">{{ followingCount > 0 ? followingCount : 0 }}</p>
      <p class="follower-title">TAKİP</p>
    </div>
  </div>
  <div class="menupp">
    <div class="menuppctn">
      <div
        class="sidebar-profile-pic"
        [ngStyle]="{ background: profilePhoto(streamerData?.profile?.picture?.path) }"
      ></div>
    </div>
    <h3 class="menu-uname">{{ streamerData?.streamerName }}</h3>
    <h4 class="menu-ustatus">
      {{ getStreamerType(streamerData?.type) }} Yayıncı <span>{{ totalPrice?.integer }}</span>
      <span class="smaller" *ngIf="totalPrice?.decimal">,{{ totalPrice?.decimal }}</span
      ><b> TL</b>
    </h4>
    <p *ngIf="totalOnlineSeconds" class="menu-ustatus">
      <span>Haftalık yayın süresi: </span><span class="bold">{{ totalOnlineHours }}</span> saat
      <span class="bold">{{ totalOnlineLeftOverMinutes }}</span> dakika
    </p>
  </div>
  <ul class="left-list">
    <li>
      <a class="anasayfa" routerLink="/streamer" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        >Ana Sayfa</a
      >
    </li>
    <li>
      <a
        class="trendler"
        routerLink="/streamer/trends"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Trendler</a
      >
    </li>
    <li><a class="cuzdan" routerLink="/streamer/wallet" routerLinkActive="active">Cüzdan</a></li>
    <li>
      <a class="mesajlar" routerLink="/message/box" routerLinkActive="active">Mesajlar</a
      ><span *ngIf="messages > 0">{{ messages }}</span>
    </li>
    <li>
      <a
        class="bildirimler"
        routerLink="/streamer/notifications"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Bildirimler</a
      ><span *ngIf="(follow$ | async) || (announcement$ | async)">{{
        (announcement$ | async) + (follow$ | async)
      }}</span>
    </li>
    <li>
      <a
        class="takipliste"
        routerLink="/streamer/followers"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Takip Listeleri</a
      ><span *ngIf="follow$ | async"> {{ follow$ | async }}</span>
    </li>
    <li>
      <a
        class="hikaye"
        routerLink="/streamer/story-history"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Hikaye Geçmişi</a
      >
    </li>
    <li>
      <a
        class="payar"
        routerLink="/streamer/profile-settings"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Profil Ayarları</a
      >
    </li>
    <li>
      <a
        class="gayar"
        routerLink="/streamer/privacy"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Gizlilik Ayarları</a
      >
    </li>
    <li class="logout"><a class="cikis" (click)="logout()">Çıkış Yap</a></li>
  </ul>
</div>
