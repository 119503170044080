import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class StreamerService {
  prodUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getWallet() { }

  updatePrivacy(obj) {
    return this.http.patch(`${this.prodUrl}streamer`, obj);
  }

  getSettingsLimits() {
    return this.http.get(`${this.prodUrl}settings/limits`);
  }


  getGallery() {
    return this.http.get(`${this.prodUrl}gallery`);
  }

  setProfilePhoto(obj) {
    return this.http.put(`${this.prodUrl}streamer/profile-picture`, obj)
  }
}
