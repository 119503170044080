<body style="height: 100%; padding-bottom: 0" class="landing-body">
  <div class='bg'></div>
  <section class="landing-section">
    <header class="landing-header">
      <div><img alt="" src="./assets/img/loginlogo.svg" /></div>
      <div>Gerçek insanlar. Canlı yayında.</div>
    </header>
    <div id="videos">
      <div class='left-arrow-container'>
        <img alt="" src='./assets/img/arrow.png' (click)='playPrevVideo()' class='story-arrow left'/>
      </div>
      <div class='right-arrow-container'>
        <img alt="" src='./assets/img/arrow.png'(click)='playNextVideo()' class='story-arrow right'/>
      </div>
      <div class='nick'>
        <span class='status-dot'></span>
        <span>{{streamerNames[currentVideoOrder-1]}}</span>
      </div>
      <video (swiperight)='playPrevVideo()' playsInline (swipeleft)='playNextVideo()' #videoFileContainer width='100%'  id="video1" autoplay [muted]="true">
        <source [src]='"./assets/video/landing" + currentVideoOrder+  ".mp4"' type="video/mp4" />
      </video>
    </div>
    <form class="form" [formGroup]="landingForm">
      <h3>Canlı Yayına Bağlan</h3>
      <div class="credentials">
        <div class="register-input-wrapper">
          <input
            (focus)="landingErrors = ''"
            (focusout)='checkIfUserNameExist()'
            type="text"
            formControlName="username"
            maxlength="15"
            class="username"
            placeholder="Kullanıcı adı"
            value=""
          />
          <img alt="" class="verfyinput" *ngIf="landingForm.controls.username.touched" [src]="usernameImg" />
        </div>
        <div class="register-input-wrapper">
          <input
            (focus)="landingErrors = ''"
            type="password"
            formControlName="password"
            class="password"
            placeholder="Şifre"
          />
          <img alt="" class="verfyinput" *ngIf="landingForm.controls.password.touched" [src]="isValidPassword()" />
        </div>
      </div>

      <label class="container"
      >Kullanıcı sözleşmesini okudum, onaylıyorum.
        <input (focus)="landingErrors = ''" type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>
      <button class="landing-button" type="submit" (click)="register()" [disabled]="!landingForm.valid">Sohbete Başla</button>
    </form>
    <!--<p class="another-option">yada</p>
    <div class="socials">
      <a>
        <img alt="" width="32" height="32" src="./assets/img/fb.svg" />
      </a>
      <a (click)="signInWithFB()">
        <img alt="" width="32" height="32" src="./assets/img/tw.svg" />
      </a>
      <a class="mr0" (click)="signInWithGoogle()">
        <img alt="" width="32" height="32" src="./assets/img/gg.svg" />
      </a>
    </div>-->
    <div class="landing-page-links">
      <a [routerLink]="['/auth/login']">Zaten üye misiniz? Giriş Yapın</a>
    </div>
  </section>
</body>
