import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import *  as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getFollowers() {
    return this.http.get(`${this.apiUrl}follow/followers`);
  }

  getFollowings() {
    return this.http.get(`${this.apiUrl}follow/followings`);
  }

  getNotifications(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}notification?${params}`);
  }

  deleteAllNotifications() {
    return this.http.delete(`${this.apiUrl}notification`);
  }

  deleteNotify(id) {
    return this.http.delete(`${this.apiUrl}notification/${id}`);
  }

  markAsRead(id) {
    return this.http.patch(`${this.apiUrl}notification/seen`, {
      ids: id,
    });
  }

  getOldStories(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}story?${params}`);
  }

  pinStory(id) {
    return this.http.put(`${this.apiUrl}story/${id}/pin`, {});
  }

  unPinStory(id) {
    return this.http.delete(`${this.apiUrl}story/${id}/pin`, {});
  }

  deleteStory(id) {
    return this.http.delete(`${this.apiUrl}story/${id}`);
  }

  approfeFollow(id) {
    return this.http.patch(`${this.apiUrl}follow/approve/${id}`, {});
  }

  rejectFollow(id) {
    return this.http.patch(`${this.apiUrl}follow/reject/${id}`, {});
  }

  blockList() {
    return this.http.get(`${this.apiUrl}block`);
  }

  updatePrivacy(obj) {
    return this.http.patch(`${this.apiUrl}user`, obj);
  }


  getSpendings() {
    const minCreatedAt = moment(new Date()).add(-90,'days').set('hours', 0).set('minutes', 0).toISOString()
    return this.http.get(`${this.apiUrl}money/spendings?sort=createdAt:desc&minCreatedAt=${minCreatedAt}`);
  }


  getPurchases() {
    const minCreatedAt = moment(new Date()).add(-90,'days').set('hours', 0).set('minutes', 0).toISOString()
    return this.http.get(`${this.apiUrl}money/purchases?sort=createdAt:desc&minCreatedAt=${minCreatedAt}`);
  }


  getCredits() {
    return this.http.get(`${this.apiUrl}settings/public/credits`);
  }

  getPackages() {
    return this.http.get(`${this.apiUrl}settings/public/packages`);
  }

  setProfilePhoto(obj) {
    return this.http.put(`${this.apiUrl}user/profile-picture`, obj);
  }

}
