import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../store/shared.service';

@Pipe({
  name: 'statusColor',
  pure: false,
})
export class StatusColorPipe implements PipeTransform {
  static statuses: { [ownerId: string]: boolean } = {};
  static streamers: { [streamerId: string]: 'public' | 'private' } = {};
  private cssMap = {
    p: {
      green: 'pgreendot',
      red: 'preddot',
      gray: 'pgraydot',
    },
    s: {
      green: 'sonlinedot',
      red: 'soflinedot',
      gray: 'sgraydot',
    },
    o: {
      green: 'profileonlinedot',
      red: 'profilereddot',
      gray: 'profilegraydot',
    }
  }

  transform(value: string, type: string, classType: 'p' | 's' | 'o' = 'p'): any {
    switch (type?.toLowerCase()) {
      case 'user':
        return value in StatusColorPipe.statuses ? this.cssMap[classType].green : this.cssMap[classType].gray;
      case 'streamer':
        return value in StatusColorPipe.streamers ? StatusColorPipe.streamers[value] === 'public' ? this.cssMap[classType].green : this.cssMap[classType].red : this.cssMap[classType].gray;

    }
  }
}
