import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetUser, UserState } from 'src/app/store/user.state';
import { Observable } from 'rxjs';
import { UserStateModel } from 'src/app/store/model/user.state.model';
import { StreamerState } from 'src/app/store/streamer.state';
import { StreamerStateModel } from 'src/app/store/model/streamer.state.model';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from 'src/app/store/auth.service';
import Swal from 'sweetalert2';
import { ErrorMapper } from 'src/app/utils/error.mapper';
import { UtilService } from 'src/app/store/util.service';

@Component({
  selector: 'app-profile-username-warning-bar',
  templateUrl: './profile-username-warning-bar.component.html',
  styleUrls: ['./profile-username-warning-bar.component.css'],
})
export class ProfileUsernameWarningBar implements OnInit {
  showUsernameForm = false;

  @Select(UserState.getUser) user$: Observable<UserStateModel>;

  @Select(StreamerState.getUser) streamer$: Observable<StreamerStateModel>;
  usernameForm: FormGroup;
  usernameImg: string;
  usernameError: string;
  disableSendButton: boolean;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private authService: AuthService,
    private fb: FormBuilder,
    private store: Store,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.usernameForm = this.fb.group({
      username: ['', Validators.required],
    });
  }
  checkIfUserNameExist() {
    this.authService
      .checkInputIsUnique('USERNAME', this.usernameForm.value['username'])
      .subscribe(
        (response) => {
          this.usernameImg = 'assets/img/valid.png';
          this.disableSendButton = false;
        },
        (error) => {
          this.usernameImg = 'assets/img/error.png';
          this.disableSendButton = true;
        }
      );
  }
  openUsernameModal() {
    this.showUsernameForm = true;
    this.ngxSmartModalService.getModal('usernameModal').open();
  }

  async isValidUsernameCharacters(event) {
    if ((event.target.value.match(/[^a-zA-Z0-9]/g) !== null)) {
      event.target.value = event.target.value.replace(/[^a-z0-9]/gi, '');
      this.usernameForm.value['username'] = event.target.value.replace(/[^a-z0-9]/gi, '');
      return false;
    }
    let usernameNumbs : any = this.usernameForm.value['username'].match(/\d/g);
    usernameNumbs =usernameNumbs && Number(usernameNumbs.join(''));

    if (this.usernameForm.value['username'].length < 5 || usernameNumbs > 99999) {
      this.usernameImg = 'assets/img/error.png';
      this.disableSendButton = true;
      return
    }
    this.checkIfUserNameExist();
  }
  changeUsername() {
    this.authService
      .changeUsername(this.usernameForm.value['username'])
      .subscribe(
        (response) => {
          let successData = {
            title: 'Kullanıcı Adınız Başarıyla Değiştirildi',
            errorMessage: '',
            icon: 'success',
            colorCode: '#26E00B',
          };
          this.utilService.dataServiceItem.next(successData);
          this.ngxSmartModalService.getModal('usernameModal').close();
          this.store.dispatch(new GetUser());
        },
        (error) => {
          let successData = {
            title: 'Kullanıcı Adı Hatası',
            errorMessage:  error.error.message.includes('forms') ? ErrorMapper.map(error.error.message) : error.error.message,
            icon: 'error',
            colorCode: '#26E00B',
          };
          this.utilService.dataServiceItem.next(successData);
        }
      );
  }
}
