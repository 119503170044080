import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PrivateLayoutComponent } from './private-layout/private-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PrivateFooterComponent } from './private-footer/private-footer.component';
import { SidebarStreamerComponent } from './sidebar-streamer/sidebar-streamer.component';
import { HeaderStreamerComponent } from './header-streamer/header-streamer.component';
import { UsernameWarningBarModule } from '../modules/username-warning/username-warning.module';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PrivateLayoutComponent,
    PublicLayoutComponent,
    SidebarComponent,
    PrivateFooterComponent,
    SidebarStreamerComponent,
    HeaderStreamerComponent],
  imports: [
    UsernameWarningBarModule,
    CommonModule,
    RouterModule.forChild([]),
  ],
  exports: [
    PrivateLayoutComponent,
    PublicLayoutComponent,
    FooterComponent,
  ]
})
export class LayoutModule { }
