import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MemberService } from 'src/app/store/member.service';
import { UserStateModel } from 'src/app/store/model/user.state.model';
import { UserState } from 'src/app/store/user.state';
import { AuthState } from '../../store/auth.state';
import { StreamerState } from '../../store/streamer.state';
import { StreamerStateModel } from '../../store/model/streamer.state.model';
import { take } from 'rxjs/operators';

declare var LiveChatWidget: any;
@Component({
  selector: 'app-private-footer',
  templateUrl: './private-footer.component.html',
  styleUrls: ['./private-footer.component.css'],
})
export class PrivateFooterComponent implements OnInit {
  ownerType;
  finalValue;
  activeCampaign;
  @Select(UserState.getUser) user$: Observable<UserStateModel>;
  @Select(StreamerState.getUser) streamer$: Observable<StreamerStateModel>;

  constructor(private store: Store, private memberService: MemberService, private router: Router) {}

  ngOnInit(): void {
    this.ownerType = this.store.selectSnapshot(AuthState.ownerType);
    if (this.ownerType === 'Streamer') {
      this.streamer$.pipe(take(3)).subscribe((streamerData) => {
        if (streamerData && streamerData.username) {
          LiveChatWidget.call('set_customer_name', streamerData.streamerName);

          LiveChatWidget.call('set_session_variables', {
            'Kullanici Tipi': 'YAYINCI',
            ID: streamerData.streamerId,
            'Kullanici Adi': streamerData.username,
            'Yayinci Adi': streamerData.streamerName,
            'Email Onay Durumu': streamerData.emailVerified ? 'Onaylandi' : 'Onaylanmadi',
            Email: streamerData.email,
          });
        }
      });
    } else {
      this.user$.subscribe((userData) => {
        if (userData && userData.username) {
          LiveChatWidget.call('set_customer_name', userData.username);
          LiveChatWidget.call('set_session_variables', {
            'Kullanici Tipi': userData.type?.type,
            'Kullanici Adi': userData.username,
            'Email Onay Durumu': userData.emailVerified ? 'Onaylandi' : 'Onaylanmadi',
            Email: userData.email,
            'Kayit Traihi': userData.createdAt,
            Kredi: userData.credit,
            'Toplam Kredi': userData.totalCredit,
          });
        }
      });
    }
  }

  isMessageUrl() {
    return this.router.url === '/message/area';
  }

  convertFormatToRestOfTheTime(date) {
    // TODO
    const endDate = new Date().getTime();
    const campaignEndDate: any = new Date(date);
    const diffMs = campaignEndDate - endDate; // milliseconds
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    const d = new Date();
    const secs = 60 - d.getSeconds();
    this.finalValue = diffDays + ':' + diffHrs + ':' + diffMins + ':' + secs;
    return this.finalValue;
  }

  toggleChatWidget() {
    LiveChatWidget.call('maximize');
  }
}
